import * as React from 'react'

import { Button } from '../../Button';
import { HBox } from '../../Box';
import { DateInput } from '../../date-utils';
import { DatePicker } from '../../DatePicker';
import { Dropdown } from '../../Dropdown';
import { Option, OptionValue } from '../../Option';
import { Text } from '../../Text';
import { Icon, IconProps } from '../../icons';
import { Link } from '../../Link';
import { ListItemRenderer } from '../../list';
import { Menu, MenuItem, MenuSeparator } from '../../menu';
import { Popup } from '../../Popup';
import { useForceUpdate } from '../../utils';
import { useBreakpoints } from '../../breakpoints';

import { DataTableColumn,  DataTableColumnSort, colId } from '../column/DataTableColumn';
import { DataTable } from '../DataTable';

interface Props extends IconProps {
  table:DataTable;
  col:DataTableColumn;
  colPos?:number;
  hide?:boolean;
}

export function ColMenu(props:Props) {
  const {table, col, colPos, color, hide, ...remaining} = props;

  const ref = React.useRef<Popup>();

  const breakpoint = useBreakpoints();
  const modal = breakpoint == 0;

  const forceUpdate = useForceUpdate();
  const [optionInfo, setOptions] = React.useState({options:[] as Option[], values:[] as OptionValue[]});
  const sortable = table.canSortFilter && (col.sortable !== undefined ? col.sortable : table.props.sortable);
  const filterable = table.canSortFilter && (col.filterable !== undefined ? col.filterable : table.props.filterable);
  const lockable = table.canSortFilter && table.props.lockable;
  const hasMenu = lockable || sortable || filterable;

  const pendingColUpdate = React.useRef<NodeJS.Timeout>()

  if (!hasMenu) {
    return <></>;
  }

  const locked = table.props.lockable && table.lockedCol === colPos;
  // we intentionally don't check sortable when indicating the sort icon
  // because a column can still be sorted, even if the user isn't allowed to change it
  const ascending = col.sort == DataTableColumnSort.ascending;
  const descending = col.sort == DataTableColumnSort.descending;
  const filtered = filterable && col.filter != null;

  function render() {
    return <Popup ref={ref} onOpen={onMenuOpen} trigger={renderMenuTrigger()} dropdown={renderMenu()} modal={modal} forceVisible />
  }

  function renderMenuTrigger() {
    return <span style={{display:'flex', flex: 1, pointerEvents: 'none', alignItems: 'center'}}>
      <Icon name='MoreHorizontal' button pointerEvents='all' color={color} {...remaining} />
      {renderIndicators()}
    </span>
  }

  function renderIndicators() {
    if (!hasMenu) {
      return null;
    }

    if (!locked && !ascending && !descending && !filtered) {
      return null;
    }

    return <>
      <span style={{flex:1, pointerEvents: 'none'}} />
      {locked &&     <Icon name='Lock' size='small' color={color} pointerEvents='all' />}
      {filtered &&   <Icon name='Filter' size='small' color={color} pointerEvents='all' />}
      {ascending &&  <Icon name='ArrowUp' size='small' color={color} pointerEvents='all' />}
      {descending && <Icon name='ArrowDown' size='small' color={color} pointerEvents='all' />}
    </>;
  }

  function renderMenu() {
    return <Menu minWidth='280px'>
      {Number.isFinite(colPos) && <MenuItem selected={locked} hidden={!table.props.lockable} onClick={onLockCol}>Lock column</MenuItem>}
      <MenuItem hidden={!table.props.hideable || hide === false} onClick={onHideCol}>Hide column</MenuItem>
      <MenuItem selected={ascending} hidden={!sortable} onClick={onSortAscending}>Sort ascending</MenuItem>
      <MenuItem selected={descending} hidden={!sortable} onClick={onSortDescending}>Sort descending</MenuItem>
      {renderFilterMenu()}
      {renderClose()}
    </Menu>
  }

  function renderFilterMenu() {
    if (!filterable) {
      return <></>;
    }

    return <>
      <MenuSeparator hidden={!sortable} />
      <Text text='subtitle2' fontWeight='normal'>Filter by</Text>
      {col.filterType != 'date-range' ? renderFilterList() : renderDateRange()}
    </>;
  }

  function renderFilterList() {
    return <>
      <HBox>
        <Link onClick={onClearAll} small mr='$30'>Clear all</Link>
        <Link onClick={onSelectAll} small>Select all</Link>
      </HBox>
      <Dropdown additionTerm={col.allowAdditionalFilterValues ? 'Filter on' : undefined} mb='$12' options={optionInfo.options} value={col.filter || optionInfo.values} onChange={onFilterChange} multiple inlinelist measuredRows maxLines={2} selectedStyle='checkbox' 
        tags={false} clearOnSelect={false} list={{makeSelectionVisible: false, border: 'none', renderer: <ListItemRenderer pl='0px' />}} />
    </>
  }

  function renderDateRange() {
    return <DatePicker inline type='range' value={col.filter} onChange={onFilterDateChange} />
  }

  function renderClose() {
    if (!modal) {
      return;
    }

    return <Button onClick={() => ref.current.close()}>Close</Button>
  }

  function onMenuOpen() {
    loadOptions();
  }

  function onHideCol() {
    table.hideCol(colId(col));
  }

  function onLockCol() {
    const isLocked = table.lockedCol == colPos;
    table.lockedCol = isLocked ? -1 : colPos;
  }

  function onSortAscending() {
    onSortFilterChange(col.sort == DataTableColumnSort.ascending ? DataTableColumnSort.none : DataTableColumnSort.ascending, col.filter);
  }

  function onSortDescending() {
    onSortFilterChange(col.sort == DataTableColumnSort.descending ? DataTableColumnSort.none : DataTableColumnSort.descending, col.filter);
  }

  function onClearAll() {
    onSortFilterChange(col.sort, []);
  }

  function onSelectAll() {
    onSortFilterChange(col.sort, null);
  }

  function onFilterChange(event:React.ChangeEvent<Dropdown>) {
    onSortFilterChange(col.sort, event.target.value);
  }

  function onFilterDateChange(event:React.ChangeEvent<DatePicker>) {
    const dates = event.target.value as DateInput[];
    onSortFilterChange(col.sort, dates?.length == 0 ? null : dates, false);
  }

  function onSortFilterChange(sort:DataTableColumnSort, filter:OptionValue[], clearIfAllSelected:boolean = true) {
    if (col.sort !== sort) {
      clearPrevSort();
      col.sort = sort;
    }

    col.filter = filter;

    if (col.filter && col.filter.length == optionInfo.options.length && clearIfAllSelected) {
      col.filter = null;
    }

    if (pendingColUpdate.current) {
      clearTimeout(pendingColUpdate.current);
      pendingColUpdate.current = null;
    }

    // this prevents not blank from being used in combination
    // with specific values because otherwise it looks broken
    // because not blank will always include all values
    if (clearIfAllSelected && col.filter?.length > 1) {
      const notBlank = col.filter.indexOf("NOT NULL");

      if (notBlank == col.filter.length - 1) {
        col.filter = ["NOT NULL"];
      }
      else if (notBlank != -1) {
        col.filter.splice(notBlank, 1);
      }
    }

    pendingColUpdate.current = setTimeout(() => {
      const onSortFilter = col.onSortFilter || table.props.onSortFilter;
      onSortFilter(table, col, col.sort, col.filter);

      table.props.onViewChange?.(table);
    }, 1000);

    // need to use forceUpdate because the col's sort/filter state is stored externally
    forceUpdate();
  }

  function clearPrevSort() {
    table.cols.forEach(col => col.sort = null);
  }

  async function loadOptions() {
    if (!filterable || col.filterType == 'date-range') {
      return
    }

    const getFilterOptions = col.getFilterOptions || table.props.getFilterOptions;
    const options = (await getFilterOptions(table, col)) || [];
    const values = options.map(option => option.value);

    sortSelected(options);
    setOptions({options, values});
  }

  // ensures that the selected list is ordered the same as the option list
  // so that the first selected gets selected when showing the menu.
  // the might not be in order if the user selected them in a different order.
  // this is only done when the menu is displayed.

  function sortSelected(options:Option[]) {
    if (!col.filter) {
      return;
    }

    const indexMap = new Map();
    options.forEach((option, index) => indexMap.set(option.value, index));

    col.filter = col.filter.slice().sort((a, b) => indexMap.get(a) - indexMap.get(b));
  }

  return render();
}
