import * as React from 'react';
import { useParams } from 'react-router';

import { CourseKind, CourseStatus, EntityKind, organizerPreferences } from 'app2/api';
import { StandardTableActions, iso8601Date } from 'app2/components';
import { courseKindBehavior } from 'app2/views/shared-public';
import { AppendRowAction, ApproveCourseAction, CreateActivities, CancelCourseAction, courseColumnSets, CoursesTableTab, CoursesTableWithTabs, CloseEnrollmentAction, DeleteRowAction, OpenEnrollmentAction, SaveAndTransition, useCourseTableState } from 'app2/views/shared';

import { SeasonRouteParams } from '../organizerRoutes';
import { useOrganizerSeasonQuery, useCurrentSite } from '../shared';

import { SendCourseRequestAction } from './SendCourseRequestAction';
import { useSiteCompany } from './useSiteCompany';

export const COURSE_TABLE_PREFS_VERSION = '38';

interface Props {
  kind:CourseKind;
}

export function SeasonCoursesTable(props:Props) {
  const { site: siteId, season: seasonId } = useParams<SeasonRouteParams>();
  const { site } = useCurrentSite();

  const [seasonResult] = useOrganizerSeasonQuery({ variables: { seasonId } });
  const season = seasonResult?.data?.season;
  const activeSeason = season && !season.completed;

  const state = useCourseTableState(organizerPreferences.seasonPreferences(seasonId).tablePreferences(props.kind));

  const behavior = courseKindBehavior[props.kind];
  const queryVars = {seasonId};

  const siteCompany = useSiteCompany(!behavior?.siteCompany);

  if (!seasonId) {
    return <span />;
  }

  function render() {
    return <CoursesTableWithTabs
      kind={props.kind}
      tabs={renderTabs()}
      site={siteId}
      queryVars={queryVars}
      state={state}
      defaultValues={getCourseDefaults()}
      prefsVersion={COURSE_TABLE_PREFS_VERSION}
      views={{entityKind:EntityKind.Site, entityId:siteId, table: `season-courses-${props.kind}`}}
    />
  }

  function renderTabs() {
    const name = behavior.name as keyof typeof courseColumnSets;
    const tabs = courseColumnSets[name];
    const hasRequests = !!(tabs as any).requests;
    const primaryActions = state.editing || !activeSeason ? undefined : <CreateActivities kind={props.kind} />;

    const tabsWithActions = [{
      ...tabs.published, 
      none: activeSeason ? undefined : renderNoneForCompletedSeason(tabs.draft), 
      primaryActions,
      secondaryActions:renderSecondaryActions(false)
    },
    {
      ...tabs.draft, 
      none: activeSeason ? undefined : renderNoneForCompletedSeason(tabs.draft), 
      primaryActions,
      secondaryActions:renderSecondaryActions(true), 
      saveActions:renderSaveActions()
    }]

    if (hasRequests) {
      tabsWithActions.unshift({
        ...(tabs as any).requests,
        primaryActions,
        saveActions:renderSaveActions(),
        secondaryActions:renderSecondaryActions(false)
      })
    }

    return tabsWithActions;
  }

  function renderNoneForCompletedSeason(tab:Partial<CoursesTableTab>) {
    return `There are no ${tab.label.toLocaleLowerCase()} activities in this season.`;
  }

  function renderSaveActions() {
    return [<SaveAndTransition state={state} queryVars={queryVars} />];
  }

  function renderSecondaryActions(drafts:boolean) {
    return {editing:drafts ? renderEditingActions() : [], display:renderNonEditingActions()};
  }

  function renderEditingActions() {
    return [
      <AppendRowAction table={state.table.current} kind={props.kind} />,
      <DeleteRowAction table={state.table.current} kind={props.kind} />,
    ]
  }

  function renderNonEditingActions() {
    return [
      <SendCourseRequestAction table={state.table.current} />,
      <CancelCourseAction table={state.table.current} kind={props.kind} />,
      <ApproveCourseAction table={state.table.current} kind={props.kind} />,
      <OpenEnrollmentAction table={state.table.current} kind={props.kind} />,
      <CloseEnrollmentAction table={state.table.current} kind={props.kind} />,
      <StandardTableActions />
    ]
  }

  function getCourseDefaults() {
    if (!season) {
      return {};
    }
    
    const defaults = {
      // because season start/end are stored with a time component but course start/end are not
      // we have to convert the season ones to pure dates, in the site timezone
      startDate: behavior.seasonDates ? iso8601Date(season?.coursesBegin, site?.timezone) : null,
      endDate: behavior.seasonDates ? iso8601Date(season?.coursesFinish, site?.timezone) : null,
      enrollmentCloses: season.enrollmentCloses,
      enrollmentOpens: season.enrollmentOpens,
      noEnrichmentDays: season.holidays?.slice(),
      season,
      status: CourseStatus.Draft,
      searchable: behavior.registerable,
      // this is important for date/time fields that need a timezone and for
      // permissions that depend on partner/nonpartner status
      site,
      vendor:siteCompany,
      ...behavior.defaults
    }

    return defaults;
  }

  return render();
}
