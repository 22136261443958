import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
import { QuestionFieldsFragmentDoc } from '../../../shared/custom-fields/generated/cfQuestionFields.generated';
export type CartedEnrollmentFieldsFragment = {
  affirmEnabled: boolean;
  pickupDropoffRequired: boolean;
  studentSelectionRequired: boolean;
  status: Types.EnrollmentStatusEnum;
  waitlistKind: Types.WaitlistKind;
  dismissal?: string | null | undefined;
  pickup?: string | null | undefined;
  discountId?: string | null | undefined;
  inGradeRange?: boolean | null | undefined;
  inAgeRange?: boolean | null | undefined;
  gradeAtStart?: string | null | undefined;
  ageAtStart?: number | null | undefined;
  startDate?: string | null | undefined;
  endDate?: string | null | undefined;
  billPeriod?: string | null | undefined;
  rosterPeriod?: string | null | undefined;
  rosterAmount?: string | null | undefined;
  listPrice: number;
  templateId?: string | null | undefined;
  isOption: boolean;
  athleticsConfig?: { teamName?: string | null | undefined; students?: Array<{ name: string; email: string }> | null | undefined } | null | undefined;
  discount?: { code?: string | null | undefined } | null | undefined;
  discountUses: Array<{ id: string; amount: number; code: string; discountDefinitionId: string; kind: Types.DiscountKind }>;
  student?: { id: string; name: string } | null | undefined;
  enrollmentPriceTierUse?: { id: string } | null | undefined;
  priceConfig?:
    | { kind: Types.PriceConfigKind; template: boolean }
    | { kind: Types.PriceConfigKind; unit: Types.RecurringUnit; weekdays: Array<string>; template: boolean; startDate: string; startDateDisabled: boolean }
    | {
        kind: Types.PriceConfigKind;
        template: boolean;
        deposit?: boolean | null | undefined;
        billingDate?: string | null | undefined;
        depositAmount?: number | null | undefined;
        installmentDates?: Array<string> | null | undefined;
        weekdays: Array<string>;
      }
    | { kind: Types.PriceConfigKind; template: boolean; usageUnit: Types.UsageUnit }
    | null
    | undefined;
  courseOption?: { kind: Types.CourseOptionKind } | null | undefined;
  optionParent?: { id: string; course: { id: string; startDate?: string | null | undefined } } | null | undefined;
  course: {
    id: string;
    name?: string | null | undefined;
    kind?: Types.CourseKind | null | undefined;
    disambiguatedName?: string | null | undefined;
    ageGroup?: string | null | undefined;
    gradeGroup?: string | null | undefined;
    startDate?: string | null | undefined;
    endDate?: string | null | undefined;
    noEnrichmentDays: Array<string | null | undefined>;
    classesCount: number;
    courseFrameworkId?: string | null | undefined;
    seasonId: string;
    teamMaxCapacity?: number | null | undefined;
    courseDays: Array<{ start?: string | null | undefined; finish?: string | null | undefined; days?: Array<string> | null | undefined } | null | undefined>;
    company?: { slug: string; name?: string | null | undefined; id: string } | null | undefined;
    site: { id: string; donationConfig: { enabled: boolean } };
    cfQuestions?:
      | Array<{
          id: string;
          ownerId?: string | null | undefined;
          ownerType?: Types.CfOwnerKind | null | undefined;
          kind?: Types.CfKind | null | undefined;
          title?: string | null | undefined;
          description?: string | null | undefined;
          choices?: Array<string> | null | undefined;
          stage?: Types.CfStage | null | undefined;
          required?: boolean | null | undefined;
          owner?: { name?: string | null | undefined } | { name: string } | { name: string } | null | undefined;
          files?: Array<{ id: string; name: string; fileType?: string | null | undefined } | null | undefined> | null | undefined;
        }>
      | null
      | undefined;
  };
  firstPayment?: { amount: number; dueOn: string; nextPaymentOn?: string | null | undefined; prorated: boolean; recurringAmount: number; recurringListPrice: number } | null | undefined;
};

export const CartedEnrollmentFieldsFragmentDoc = gql`
  fragment cartedEnrollmentFields on Enrollment {
    affirmEnabled
    athleticsConfig {
      teamName
      students {
        name
        email
      }
    }
    pickupDropoffRequired
    studentSelectionRequired
    status
    waitlistKind
    dismissal
    pickup
    discount {
      code
    }
    discountId
    discountUses {
      id
      amount
      code
      discountDefinitionId
      kind
    }
    student {
      id
      name
    }
    enrollmentPriceTierUse {
      id
    }
    inGradeRange
    inAgeRange
    gradeAtStart
    ageAtStart
    startDate
    endDate
    billPeriod
    rosterPeriod
    rosterAmount
    priceConfig {
      ... on SeasonPriceConfig {
        kind
        template
        deposit
        billingDate
        depositAmount
        installmentDates
        weekdays
      }
      ... on RecurringPriceConfig {
        kind
        unit
        weekdays
        template
        startDate
        startDateDisabled
      }
      ... on DropInPriceConfig {
        kind
        template
      }
      ... on UsagePriceConfig {
        kind
        usageUnit: unit
        template
      }
    }
    listPrice
    templateId
    isOption
    courseOption {
      kind
    }
    optionParent {
      id
      course {
        id
        startDate
      }
    }
    course {
      id
      name
      kind
      disambiguatedName
      ageGroup
      gradeGroup
      startDate
      endDate
      courseDays {
        start
        finish
        days
      }
      noEnrichmentDays
      classesCount
      courseFrameworkId
      company {
        slug
        name
        id
      }
      seasonId
      site {
        id
        donationConfig {
          enabled
        }
      }
      cfQuestions {
        ...questionFields
      }
      teamMaxCapacity
    }
    firstPayment {
      amount
      dueOn
      nextPaymentOn
      prorated
      recurringAmount
      recurringListPrice
    }
  }
  ${QuestionFieldsFragmentDoc}
`;
