import * as React from 'react';
import moment from 'moment';
import { flatten } from 'lodash-es';
import { useParams } from 'react-router';
import { print } from 'graphql'

import { EnrollmentStatusFilter, EntityKind, organizerPreferences, CfOwnerKind, RosterGroupingKind, SeasonUtils } from 'app2/api';
import { ActionButton, Button, DataTableColumnWithStringName, DatePicker, GroupedDataTableHandle, DataTableColumn, MenuItem, Tabs, useStateWithDeps } from 'app2/components';
import { byAll, byActivity, byClassroom, byDismissal, bySession, downloadRoster, ContentType, HrDataTable, copyEmails, PAGE_WIDTH, ReportView, ViewAttribute, useCfQuestionCols, createRosterView } from 'app2/views/shared';
import { Beta, courseKindBehavior } from 'app2/views/shared-public';

import { EnrollmentRouteParams } from '../organizerRoutes';
import { OrganizerPage, SeasonDropdown, useCurrentSite } from '../shared';

import { OrganizerSeasonEnrollmentsSelections, OrganizerSeasonEnrollmentsDocument, useOrganizerSeasonEnrollmentsQuery, useOrganizerSeasonEnrollmentsSeasonQuery } from './gql';

const TABLE_PREFS_VERSION = '10';

export function Enrollments() {
  const { site: siteSlug, season:seasonId, view } = useParams<EnrollmentRouteParams>();
  const { site } = useCurrentSite();
  const [seasonResult] = useOrganizerSeasonEnrollmentsSeasonQuery({variables:{seasonId}});
  const season = seasonResult?.data?.season;

  const tabNo = Math.max(orderedViews.findIndex(tab => tab.name == view), 0);

  const byWeekday = season?.courseKindGroups.some(g => courseKindBehavior[g].rostersGroupByDay) && tabNo != 0;
  const [options, setOptions] = useStateWithDeps({byWeekday, weekday: byWeekday ? moment().format('dddd') : undefined, date: undefined as moment.Moment}, [byWeekday]);

  const tabs = getTabs();
  const table = React.useRef<GroupedDataTableHandle<OrganizerSeasonEnrollmentsSelections>>();

  const query = getQuery();

  function render() {
    const subtitle = "You can download your rosters on this page. Your reports will be divided by activity, classroom, or dismissal options."
    return <OrganizerPage title="Enrollments" subtitle={subtitle}>
      <SeasonDropdown />
      {season && <Tabs width="100%" baseUrl={`/organizer/${siteSlug}/enrollments/${seasonId}/:view`} urlParameter='view' tabs={tabs.map(tab => renderTab(tab))} />}
    </OrganizerPage>
  }

  function renderTab(tab: ReturnType<typeof getTabs>[0]) {
    const optionVariables = {date: options.date, filters: byWeekday && options.weekday ? {byDay: [options.weekday]} : undefined}

    return {
      label: tab.label,
      name: tab.name,
      content: <HrDataTable header={{ icon: 'Users', title: 'Enrollments', primaryActions: renderPrimaryActions(), secondaryActions:renderSecondaryActions(), options: renderMenuOptions(), groupByEnabled: true, groupByOptions: tabs[0].table.cols }}
        views={{entityKind: EntityKind.Site, entityId: site?.id, table: 'season-enrollments'}}
        {...tab.prefs}
        queryHook={useOrganizerSeasonEnrollmentsQuery} queryOptions={{ query, autoPause: false, variables: { groupingId: seasonId, enrollmentStatus: EnrollmentStatusFilter.Rostered, ...optionVariables }}}
        table={{ none: 'No enrollments', rowSelection:false, ref: table, ...tab.table, cellStyle: 'read-only', pageWidth: PAGE_WIDTH}} />
    };
  }

  function renderPrimaryActions() {
    const legend = SeasonUtils.getSeasonDates(season);

    return [
      <DatePicker clearable value={options.date} daysOfWeek={byWeekday} dayOfWeek={options.weekday} onChange={onChangeDate} legend={legend} shadowFormat='MMM D, YYYY ddd' />,
      <Button onClick={downloadCsv} icon='Download' iconPosition='left' autoLoader kind='secondary'>CSV</Button>,
      <Button onClick={downloadPdf} icon='Download' iconPosition='left' autoLoader>PDF</Button>
    ]
  }

  function renderSecondaryActions() {
    return [
      <ActionButton selection={false} icon='User' onClick={copyParentEmailsToClipboard}>Copy family emails</ActionButton>,
      <ActionButton selection={false}icon='User' onClick={copyProviderEmailsToClipboard}>Copy provider emails</ActionButton>,
    ]
  }

  function renderMenuOptions() {
    return tabNo == 0 ? [<Beta><MenuItem autoLoader onClick={downloadSessionCsv}>Download attendance dates</MenuItem></Beta>] : []
  }

  function getQuery() {
    return React.useMemo(() => {
      let query = print(OrganizerSeasonEnrollmentsDocument);

      if (!options.byWeekday || options.date) {
        query = query.replace('weekday', '');
      }

      if (!options.byWeekday && !options.date) {
        query = query.replace(/otherEnrollments {[^}]*}[^}][^}]*}/gms, '');
      }

      return query;
    }, [options]);
  }

  function onChangeDate(event:React.ChangeEvent<DatePicker>) {
    setOptions({...options, date:event.target.value as moment.Moment, weekday: event.target.dayOfWeek});
  }

  function copyParentEmailsToClipboard() {
    const enrollments = table.current.tables[0].current.allItems;
    copyEmails(enrollments.map(e => flatten([e.parent.email, e.parent.ccContacts?.map(c => c.email)])));
  }

  function copyProviderEmailsToClipboard() {
    const enrollments = table.current.tables[0].current.allItems;
    copyEmails(enrollments.map(e => e.course.vendor.email));
  }

  function downloadCsv() {
    return downloadReportHelper(ContentType.CSV);
  }

  function downloadSessionCsv() {
    const cfCols = table.current.props.cols.filter(c => c.name.startsWith('cf'));
    const session = {...bySession, cols: bySession.cols.concat(cfCols as any) as DataTableColumnWithStringName[]};
    return downloadReportHelper(ContentType.CSV, EnrollmentStatusFilter.Session, session, ['title'], false);
  }

  function downloadPdf() {
    return downloadReportHelper(ContentType.PDF, EnrollmentStatusFilter.Rostered, null, ['title'], false);
  }

  function downloadReportHelper(contentType:ContentType, enrollmentStatus = EnrollmentStatusFilter.Rostered, view:ReportView = null, viewAttributes:ViewAttribute[] = ['title'], appendRemainingColumns:boolean = true) {
    const fileName = ['Homeroom roster', season.name, options.date];
    return downloadRoster(fileName, contentType, 'rosterByGrouping', { groupingId: seasonId, groupingKind: RosterGroupingKind.Season, enrollmentStatus, date: options.date }, table.current, view || tabs[tabNo].view, viewAttributes, appendRemainingColumns);
  }

  function getTabs() {
    const cfCols = useCfQuestionCols<any>({ownerType: CfOwnerKind.Season, ownerId: seasonId});

    return React.useMemo(() => {
      return [
        createTab(views.all, cfCols, false), 
        createTab(views.activity), 
        createTab(views.classroom), 
        createTab(views.dismissal)
      ]
    }, [season, cfCols, options.weekday, options.date]);
  }

  function createTab(def:typeof views['all'] | typeof views['activity'], cfCols:DataTableColumn[] = [], scaleCols:boolean = true) {
    const prefs = { prefs: organizerPreferences.sitePreferences(siteSlug), prefsKey: organizerPreferences.sitePreferences(siteSlug).seasonEnrollments(def.name), prefsVersion: TABLE_PREFS_VERSION };
    const view = createRosterView({site, season, cfCols, scaleCols, byWeekday, date: options.date, status: EnrollmentStatusFilter.Rostered}, def);

    return {
      label: def.label,
      name: def.name,
      view,
      table: {cols: view.cols, groupBy: view.groups},
      prefs
    }
  }

  return render();
}

const views = {
  all: {
    label: 'All',
    name: 'all',
    ...byAll
  },
  activity: {
    label: 'By activity',
    name: 'activity',
    ...byActivity,
  },
  classroom: {
    label: 'By classroom',
    name: 'classroom',
    ...byClassroom,
  },
  dismissal: {
    label: 'By dismissal',
    name: 'dismissal',
    ...byDismissal,
  }
}

const orderedViews = [
  views.all,
  views.activity,
  views.classroom,
  views.dismissal
]