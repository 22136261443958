import * as React from 'react'

import { CourseKind } from 'app2/api'
import { DropdownField, OptionPicker, OptionPickerProps } from 'app2/components';

interface CourseKindPickerProps extends OptionPickerProps {
  athletics?:boolean;
}

export function CourseKindPicker(props:CourseKindPickerProps) {
  const {athletics, ...remaining} = props;

  return <OptionPicker options={athletics ? regFeeOptions : courseKindOptions} {...remaining} />
}

CourseKindPicker.fieldProps = OptionPicker.fieldProps;
CourseKindPicker.defaultProps = {
  type: 'button-strip'
}

const regFeeOptions = [{
  label: 'Team fee',
  value: CourseKind.AthleticReg,
}, {
  label: 'Individual fee',
  value: CourseKind.AthleticIndividualReg,
}];

const courseKindOptions = [{
  label: 'Enrichment',
  value: CourseKind.Enrichment,
}, {
  label: 'Extended care',
  value: CourseKind.ExtendedCare,
}, {
  label: 'Camp',
  value: CourseKind.Camp,
}, {
  label: 'Lessons',
  value: CourseKind.LessonSet,
}, {
  label: 'Time slot',
  value: CourseKind.TimeSlot,
}, 
...regFeeOptions, {
  label: 'Team',
  value: CourseKind.Team,
}, {
  label: 'Game',
  value: CourseKind.Game,
}];

export const CourseKindField = {edit: {...DropdownField.edit, type: 'dropdown', component: CourseKindPicker}, display: DropdownField.display, options: courseKindOptions};
export const AthleticsCourseKindField = {...CourseKindField, edit: {...CourseKindField.edit, athletics: true}, display: CourseKindField.display, options: regFeeOptions};
