import { CourseUtils, PriceConfig, PriceConfigKind, Prices, RecurringPriceConfig, SeasonPriceConfig, UsagePriceConfig } from 'app2/api';
import { formatCurrency } from 'app2/components';
import { CartCourse, courseKindBehavior, formatPriceAndLabel, formatRecurringPrice, formatUsagePrice, coursePriceUnitLabels, formatConfigurableSeasonPrice } from 'app2/views/shared-public';

export function formatPriceConfig(course: CartCourse, priceConfig:Partial<PriceConfig>, alternatePrice?:number) {
  if (!course?.prices || !priceConfig) {
    return formatCurrency(alternatePrice);
  }
  const behavior = courseKindBehavior[course?.kind];

  if (priceConfig.kind === PriceConfigKind.Season) {
    if ((priceConfig as SeasonPriceConfig).weekdays?.length) {
      return formatConfigurableSeasonPrice(behavior, CourseUtils.findConfigurableSeasonPriceForConfig(course.prices, priceConfig as SeasonPriceConfig), alternatePrice);
    }

    return formatPriceAndLabel(alternatePrice ?? course.prices.season.price);
  } else if (priceConfig.kind === PriceConfigKind.DropIn) {
    return formatPriceAndLabel(alternatePrice ?? course.prices.dropIn.price);
  } else if (priceConfig.kind === PriceConfigKind.Recurring) {
    return formatRecurringPrice(CourseUtils.findRecurringPriceForConfig(course.prices, priceConfig as RecurringPriceConfig), alternatePrice);
  } else if (priceConfig.kind === PriceConfigKind.Usage) {
    return formatUsagePrice(CourseUtils.findUsagePriceForConfig(course.prices, priceConfig as UsagePriceConfig), alternatePrice);
  }
}

export function formatDiscountForPriceConfig(prices:Partial<Prices>, priceConfig:Partial<PriceConfig>, discount?:number) {
  const amount = formatCurrency(discount || 0);

  if (!prices || !priceConfig) {
    return formatCurrency(amount);
  }

  if (priceConfig.kind === PriceConfigKind.Season || priceConfig.kind === PriceConfigKind.DropIn) {
    return amount;
  } else if (priceConfig.kind === PriceConfigKind.Recurring) {
    return `${amount}/${coursePriceUnitLabels[CourseUtils.findRecurringPriceForConfig(prices, priceConfig as RecurringPriceConfig).unit]}`;
  } else if (priceConfig.kind === PriceConfigKind.Usage) {
    return `${amount}/${coursePriceUnitLabels[CourseUtils.findUsagePriceForConfig(prices, priceConfig as UsagePriceConfig).unit]}`;
  }
}
