import * as React from 'react';

import { EntityKind } from 'app2/api';
import { UnifiedTeam } from 'app2/views/shared';

import { OrganizerPage, useCurrentSite } from '../shared';

export function Team() {
  const { site } = useCurrentSite();

  return <OrganizerPage title="Team">
    <UnifiedTeam entityKind={EntityKind.Site} entityId={site?.id} cols={cols} numbered panel editing={true} />
  </OrganizerPage>
}

const cols = ['email', 'role', 'isContact', 'sentAt', 'resendInvite']