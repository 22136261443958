import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { Attendance } from './attendance';

export const obfuscatedRoutes = [
  <Route path="/activities/:token/attendance/:date?" component={Attendance} />,

  <Redirect from='/attendance/:token/check-in/:date?' to="/activities/:token/attendance/:date" />,
  <Redirect from='/attendance/:token/classes/:date?' to="/activities/:token/attendance/:date" />
]

export interface AttendanceRouteParams {
  token: string;
}
