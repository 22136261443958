import * as React from 'react';
import { useHistory } from 'react-router'
import qs from 'query-string'

import { Course, CourseKind, CourseUtils } from 'app2/api';
import { Calendar, Form, HBox, VBox, Phone, TabletOrDesktop } from 'app2/components';
import { ActivityCardWithPriceUpdates, courseKindBehavior, removePathSegment, createCoursePayload, waitlistPresentCheck } from 'app2/views';
import { useFormPanelEditingState, calcTransitionType } from 'app2/views/shared';

import { CfQuestionsEditor } from '../../custom-fields';
import { upsertCourses, upsertCoursesErrorTransform } from '../../course/edit';
import { SaveAndTransitionModal } from '../../course/course-actions';
import { CourseSelections } from '../generated';

import { ActivityDetails } from './ActivityDetails';
import { ProviderInfo } from './ProviderInfo';
import { ActivityForm } from '../ActivityForm';
import { HiddenWarning } from './HiddenWarning';
import { CourseOptionsEditor } from './course-options';

interface Props {
  course:CourseSelections;
  form:ActivityForm;
  formUi?:React.RefObject<Form>;
  containerVars:{seasonId:string} | {companyId:string} | {courseId:string};
}

export function ActivityInformation(props:Props) {
  const { course, form } = props;

  const history = useHistory();

  const { editing: editingCourse, handleToggleEditing } = useFormPanelEditingState(urlHasEdit());
  const formValues = form.values;
  const behavior = courseKindBehavior[formValues?.kind];
  const showOptions = course?.behavior?.options;

  const dates = CourseUtils.getCourseDates(formValues);

  function render() {
    return <VBox gap='$8'>
      <HBox width="100%" layout={['vbox', 'hbox']} gap='$8'>
        <VBox width="100%">
          {!editingCourse && course && !course.searchable && <HiddenWarning />}
          <ActivityDetails editing={editingCourse} formUi={props.formUi} form={form} onToggleEditing={onToggleEditing} onOk={handleSubmit} />
          <ProviderInfo editing={editingCourse} form={form} onOk={handleSubmit} />
          <TabletOrDesktop>
            {showOptions && <CourseOptionsEditor form={form} season={course?.season?.id} onOk={handleSubmit} />}
            {behavior?.management?.customFields && <CfQuestionsEditor owner={course} courseKinds={[course?.kind]} />}
          </TabletOrDesktop>
        </VBox>
        <VBox maxWidth='362px' maxHeight='800px' top='0px' position='sticky' gap='$12'>
          {behavior?.management?.preview && <ActivityCardWithPriceUpdates course={formValues} kind='card-preview' />}
          {behavior?.management?.calendar && <Calendar start={dates.start} legend={dates.legend} />}
        </VBox>
      </HBox>
      <Phone>
        {showOptions && <CourseOptionsEditor form={form} season={course?.season?.id} onOk={handleSubmit} />}
        {behavior?.management?.customFields && <CfQuestionsEditor owner={course} courseKinds={[course?.kind]} />}
      </Phone>
    </VBox>
  }

  async function handleSubmit(form: ActivityForm, buttonNo?:number) {
    const values = form.values;
    const exit = await waitlistPresentCheck([values]);

    if (exit) {
      return;
    }

    const transition = calcTransitionType(form.values);

    if (buttonNo === 2 && !transition.addTimeSlots) {
      let result:boolean = false;

      await SaveAndTransitionModal.show(form.values, async () => {
        result = await finishSubmit(form, true);

        // we return true no matter what so the dialog closes
        return true;
      });

      return result;
    }
    else {
      return finishSubmit(form, false, transition.addTimeSlots);
    }
  }

  function onToggleEditing(editing: boolean) {
    // this removes edit mode
    if (urlHasEdit()) {
      history.replace(history.location.pathname);
    }

    handleToggleEditing(editing);
  }

  function urlHasEdit() {
    const startInEdit = 'edit' in (qs.parse(history.location.search) || {});

    return startInEdit;
  }

  async function finishSubmit(form:ActivityForm, transition:boolean, lessonRequest?:boolean) {
    // form handler already pre-validates, but we might have required turned off
    // due to draft mode, but if we are transitioning, we need to validate things
    // so we validate again
    if (transition) {
      form.validateSync({validateRequired:true});

      if (!form.valid) {
        return;
      }
    }

    const values = form.values;
    const attributes = createCoursePayload(values as unknown as Course);
    const variables = { ...props.containerVars, transition, attributes };
    const [success] = await upsertCourses({ 
      variables, 
      error: {handler: form, transform: [removePathSegment('attributes'), removePathSegment('0'), upsertCoursesErrorTransform]}, 
      successMsg: lessonRequest ? 'Lesson set saved, please add time slots' : 'Activity saved'
    });

    if (success && lessonRequest) {
      // we need to delay this until the form ui has updated else the user 
      // gets asked are they sure they want to navigate
      setTimeout(() => history.push(history.location.pathname.replace('/info', '') + '/time-slots'), 500);
    }

    return success;
  }

  return render();
}
