import { DataTable, DataTableColumn, GroupedDataTableHandle } from 'app2/components';

import { ContentType, createReportViewFromTable, downloadReport, ReportView, reportCols } from '../datatable';

import { rosterColsSortFilterParams, createRosterView } from './createRosterView';

export type ViewAttribute = Omit<(keyof ReportView), 'groups' | 'sort'>

export function downloadRoster(fileName:string | any[], contentType:ContentType, query:string, variables:any, groupedOrRegularTable:DataTable | GroupedDataTableHandle, view?:ReportView, viewAttributes:ViewAttribute[]  = ['title'], appendRemainingColumns?:boolean) {
  return downloadReport(fileName, contentType, createRosterReportFromTable(contentType, query, variables, groupedOrRegularTable, view, viewAttributes, appendRemainingColumns));
}

export function createRosterReportFromTable(contentType:ContentType, query:string, variables:any, groupedOrRegularTable:DataTable | GroupedDataTableHandle, view?:ReportView, viewAttributes:ViewAttribute[] = ['title'], appendRemainingColumns?:boolean):ReportView {
  const report = createReportViewFromTable(query, variables, groupedOrRegularTable, rosterColsSortFilterParams);

  view = createRosterView({...variables, scaleCols: true}, view);

  if (viewAttributes.includes('title')) {
    report.title = view.title;
  }

  if (viewAttributes.includes('groups')) {
    report.groups = reportCols(view.groups as DataTableColumn[]);
  }

  if (viewAttributes.includes('cols')) {
    report.cols = reportCols(view.cols as DataTableColumn[]);
  }

  return report;
}
