import * as React from 'react'

import { Box, BoxProps, HBox, VBox } from '../Box';
import { Checkbox } from '../Checkbox';
import { Icon, IconProps } from '../icons';
import { FCT, Field as BaseField, FieldProps, FieldRendererProps, separateFieldProps, useFormInfo } from '../form';
import { Text, TextProps } from '../Text';

import { FieldError } from './FieldError';

export type RendSectionOrComponent = (label:React.ReactNode, color:string, error:string, renderProps:FieldRendererProps, props?:BoxProps) => React.ReactElement;

export type FieldWithLabelProps<T = any, P extends keyof T = any, C1 extends FCT = any, C2 extends FCT = any> = FieldProps<T, P, C1, C2> & {
  label?:React.ReactNode;
  description?:React.ReactNode;
  infoTip?:React.ReactNode;
  infoTipIcon?:IconProps;
  hide?:boolean;
  hidden?:boolean;
  errorPlacement?:'above' | 'below';

  labelProps?:TextProps;
  outer?:BoxProps;
  inner?:BoxProps;

  checkbox?:boolean;
  checked?:boolean;
  onCheck?:() => void;
}

export function FieldWithLabel<T = any, P extends keyof T = any, C1 extends FCT = any, C2 extends FCT = any>(props:FieldWithLabelProps<T, P, C1, C2>) {
  let {label:propsLabel, description, infoTip, infoTipIcon, hide, hidden, errorPlacement, labelProps, outer, inner, checkbox, checked, onCheck, mode, children, vAlign, hAlign, height, ...remaining} = props;
  const form = useFormInfo();

  const formMode = form?.editing ? 'edit' : 'display';
  if (mode === 'none' || (mode !== undefined && mode != 'both' && mode != formMode)) {
    return <></>;
  }

  // for fields that define the label
  propsLabel = propsLabel || props.component?.label;

  const separatedProps = separateFieldProps(props);

  function render() {
    return !separatedProps.hasField
      ? renderComponentAndLabel(computeLabel(), undefined, undefined,  {children} as FieldRendererProps)
      : <BaseField label={propsLabel} {...remaining as any} render={fieldRenderCallback}>{children}</BaseField>
  }

  function fieldRenderCallback(renderProps:FieldRendererProps) {
    const errors = renderProps.info?.errors;
    const hasErrors = errors && errors.length != 0;
    const error = errors?.join('; ');
    const color = hasErrors ? 'error' : undefined;
    const actualLabel = computeLabel(renderProps, color);
    const render = remaining.render as FieldProps<any, any, any>['render'];

    if (render) {
      renderProps.children = render(renderProps);
    }

    return renderComponentAndLabel(actualLabel, color, error, renderProps);
  }

  function computeLabel(renderProps?:FieldRendererProps, color?:string) {
    let actualLabel = propsLabel || renderProps?.componentProps.label;
    actualLabel = addRequired (renderProps, actualLabel);
    actualLabel = addInfoTip(actualLabel, infoTip, infoTipIcon);
    actualLabel = !renderProps?.componentHasLabel && actualLabel
      ? <Text color={color} disabled={renderProps?.info?.disabled || props.disabled} {...labelProps} mb={props.checkbox ? 'unset' : labelProps.mb}>{actualLabel}</Text>
      : null;

    return actualLabel;
  }

  function addRequired(renderProps:FieldRendererProps, label:React.ReactNode) {
    // this does similar to what mergeFieldComponentProps will do when
    // computing a placeholder and try to use required at any level its specified
    // because it might be turned off in the actual edit mode, but if its specified
    // at a higher level it means its still desired to show it (just not enforce it)

    return !label
      ? undefined
      : !renderProps?.editing || typeof label != 'string'
        ? label
        : label + (props.displayRequired || props.required || renderProps?.field?.required ? ' *' : '') 
  }

  function renderComponentAndLabel(label:React.ReactNode, color:string, error:string, renderProps:FieldRendererProps) {
    return <VBox width='100%' height={height} vAlign={vAlign} hAlign={hAlign} data-field={(props as any)['data-field'] || (typeof label == 'string' && label) || (typeof propsLabel == 'string' && propsLabel) || renderProps?.field?.name} display={props.hide || props.hidden ? 'none' : undefined} {...outer}>
      {checkbox && form?.editing 
        ? <Checkbox label={label} checked={checked} onClick={onCheck}>{renderComponent(renderProps, error)}</Checkbox>
        : <>
        {label}
        {renderComponent(renderProps, error)}
      </>}
    </VBox> 
  }

  function renderComponent(renderProps:FieldRendererProps, error:string) {
    const children = renderProps.componentHasLabel && infoTip && React.isValidElement(renderProps.children)
      ? React.cloneElement(renderProps.children as React.ReactElement, {infoTip})
      : renderProps.children

    return <>
      {props.description && <Text text="formlabel" preserveWhitespace mb='$8' error={error?.length > 0}>{props.description}</Text>}
      {errorPlacement == 'above' && <FieldError error={error} mt={0} mb='$4' />}
      <Box text='body' {...inner}>{children}</Box>
      {errorPlacement != 'above' && <FieldError error={error} />}
    </>
  }

  return render();
}

export function addInfoTip(label:React.ReactNode, infoTip:React.ReactNode, infoTipIcon?:IconProps) {
  return infoTip
    ? <HBox vAlign='center' tooltip={infoTip}>{label}<Icon ml='$4' infoTip {...infoTipIcon} /></HBox>
    : label;
}
