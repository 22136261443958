import { DiscountFormat } from 'app2/api';

import { createRateCombo } from '../../../../currency';

export interface EnrollmentDiscountForm {
  format:DiscountFormat;
  rate:number;
}

export const EnrollmentDiscountCombo = createRateCombo<'format', 'rate', DiscountFormat>('format', 'rate', DiscountFormat.Number, DiscountFormat.Percentage, true);

