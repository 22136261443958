import * as React from 'react';
import { useQuery as urqlQuery, UseQueryArgs as UrqlUseQueryArgs, OperationContext, UseQueryResponse } from 'urql';
import moment from 'moment';

import { DebounceSettings } from 'app2/api';
import { useLoader, errorToString } from 'app2/components';
import { handleErrors, ErrorHandlerInfoOrHandlers, showErrors } from 'app2/views/shared/error';
import { useDebounce } from 'app2/views/shared/utils/useDebounce';

import { applyAdditionalTypenames } from './applyAdditionalTypenames';
import { removeQueryFields } from './removeQueryFields';

// urql useQuery wrapper that:
// - automatically shows a loader unless disabled by context.hideLoader
// - adds generated additionalTypenames if present
// - debounces requests if context.debounce object present

export interface UseQueryArgs<V, T> extends Omit<UrqlUseQueryArgs<V, T>, 'context'> {
  hideLoader?: boolean;
  debounce?: {
    delay?: number;
    options?: DebounceSettings;
  };
  error?: ErrorHandlerInfoOrHandlers;
  autoPause?: boolean;
  context?: Partial<Pick<OperationContext, 'additionalTypenames'>>,
  removeFields?:string[]
}

declare module "urql" {
  interface UseQueryState<Data = any, Variables = object> {
    loading?: boolean;
  }
}

export function useQuery<V = any, T = any>(options: UseQueryArgs<V, T>) {
  options = {...options};
  options.pause = autoPauseQuery(options);
  delete options.autoPause;

  const context = useAdditionalTypenames(options);
  const debounced = useDebounce({variables: options.variables, pause: options.pause, removeFields: options.removeFields}, options.pause ? 0 : options.debounce?.delay, options.debounce?.options);
  const query = React.useMemo(() => debounced.removeFields ? removeQueryFields(options.query, debounced.removeFields) : options.query, [debounced.removeFields]) as UseQueryArgs<V, T>['query'];
  const response = urqlQuery({ requestPolicy: 'cache-and-network', ...options, query, pause: debounced.pause, variables: debounced.variables, context });
  const showLoader = options?.hideLoader ? false : response[0].fetching;

  // loading is for the initial fetch not for all fetches
  response[0].loading = (options.pause || response[0].fetching) && !response[0].stale;

  useLoader(showLoader);

  // have to use an effect to show the error because
  // we can't create a modal while we're rendering (due to react restrictions)
  React.useEffect(() => {
    if (response[0].error && !(response[0].error as any).processed) {
      (response[0].error as any).processed = true;

      handleErrors(options.error, response[0], errors => {
        showErrors(errors.map(error => errorToString(error)));
        return [];
      });
    }
  });

  return response;
}

function useAdditionalTypenames<D = any, V = object>(args: UseQueryArgs<V, D>) {
  return React.useMemo(() => applyAdditionalTypenames<D, V>(args.query, args.context), [args.context]);
}

export function autoPauseQuery<D = any, V = object>(args: Pick<UseQueryArgs<V, D>, 'variables' | 'autoPause' | 'pause'>) {
  if (args == null) {
    return false;
  }

  const hasPause = 'pause' in args;

  if (hasPause) {
    return args.pause;
  }

  if (args.variables === undefined || args.variables === null) {
    return false;
  }

  const autoPauseEnabled = args.autoPause === undefined || args.autoPause;

  return autoPauseEnabled && hasNullOrUndefined(args.variables);
}

export function hasNullOrUndefined(o: any) {
  if (o === undefined || o === null) {
    return true;
  }

  if (moment.isMoment(o)) {
    return false;
  }

  if (typeof o == 'object') {
    for (const key of Object.keys(o)) {
      if (hasNullOrUndefined(o[key])) {
        return true;
      }
    }
  }

  return false;
}

export type QueryArgs<V, D> = Omit<UseQueryArgs<V, D>, 'query'>;
export type QueryHook<V, D> = (options:QueryArgs<V, D>) => UseQueryResponse<D, V>;

