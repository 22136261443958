import * as React from 'react';

import { CurrentUserInfo, NavigationSection, NavigationLink } from 'app2/views/shared-public';

import { getCompanyRoutePath } from './companyRoutes';

export const companyLinks = <NavigationSection icon='Clipboard' name='Provider' initialOpenState={getInitialOpenState}>
  <NavigationLink to={getCompanyRoutePath('quick-start-guide')} label='Quick start guide' hide={user => user.roles?.company?.quickStartGuide?.hidden} />
  <NavigationLink to={getCompanyRoutePath('schedule')} label='Schedule' />
  <NavigationLink to={getCompanyRoutePath('activities')} label='Activities' />
  <NavigationLink to={getCompanyRoutePath('templates')} label='Activity templates' />
  <NavigationLink to={getCompanyRoutePath('general-info')} label='General info' />
  <NavigationLink to={getCompanyRoutePath('payment')} label='Payment tools' />
  <NavigationLink to={getCompanyRoutePath('team')} label='Team' />
  <NavigationLink to={getCompanyRoutePath('schools')} label='Schools' />
  <NavigationLink to={getCompanyRoutePath('instructors')} label='Instructors' />
  <NavigationLink to={getCompanyRoutePath('reports')} label='Reports' />
  <NavigationLink to={getCompanyRoutePath('messages')} label='Messages' />
</NavigationSection>

function getInitialOpenState(user:CurrentUserInfo['user']) {
  const orgProvider = !!user?.roles?.site?.userSiteRole && !!user?.roles?.site?.userCompanyRole;

  return !orgProvider;
}