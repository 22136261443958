import { CourseInput, Course } from 'app2/api';
import { DataTable } from 'app2/components';
import { removePathSegment, createCoursePayload, waitlistPresentCheck } from 'app2/views/shared';

import { CoursesQueryVars, upsertCourses, upsertCoursesErrorTransform } from '../course/edit';

import { TableState } from './useCourseTableState';
import { TableCoursesSelections } from './generated'

export async function saveCoursesTable(table:DataTable, query:Partial<CoursesQueryVars>, tableState:TableState, transition?:boolean) {
  const valid = await table.presubmit({skipValidatingEmpty: true, validateRequired: transition});

  if (!valid) {
    return false;
  }

  const courses:TableCoursesSelections[] = table.getItems(!transition);
  const upserted:CourseInput[] = [];

  for (const course of courses) {
    upserted.push(createCoursePayload(course as unknown as Course));
  }

  if (!upserted.length) {
    tableState.setEditing(false);
    return true;
  }

  const exit = await waitlistPresentCheck(courses);

  if (exit) {
    return false;
  }

  const [success] = await upsertCourses({
    variables: {
      ...query,
      transition,
      attributes: upserted
    },
    error: {
      handler: table,
      transform: [removePathSegment('attributes'), upsertCoursesErrorTransform]
    }
  });

  if (success) {
    tableState.setEditing(false);
  }

  return success;
}
