import * as React from 'react'

import { CompanyPage } from '../shared'

import { CompanyInfo } from './CompanyInfo'
import { Images } from './Images'
import { CancellationPolicy } from './CancellationPolicy'

export function GeneralInfo() {
  return <CompanyPage title='General info'>
    <CompanyInfo />
    <Images />
    <CancellationPolicy />
  </CompanyPage>
}