import * as React from 'react'

import { CfQuestion, CfKind, CfOwnerKind, CfStage } from 'app2/api'
import { CheckboxField, Collapsible, Dropdown, FieldInfo, FormContent, InputField, OptionText, Part, Section, TextArea, useFormInfo, useFormSubscription } from 'app2/components';
import { CoursePicker, FileUploads, CfQuestionInput } from 'app2/views/shared';
import { CourseKindPicker } from 'app2/views/shared-public';

interface Props {
  value:CfQuestion;
  ownerType?:CfOwnerKind;
  ownerId?:string;
}

export function CfQuestionConfigure(props:Props) {
  useFormSubscription({});

  const formInfo = useFormInfo();
  const question:CfQuestion = props.value;

  function render() {
    return formInfo.editing
      ? renderEditable()
      : renderDisplay()
  }

  function renderEditable() {
    return <FormContent>
      <Section name='title' label='Title' edit={InputField} required />
      <Section label='Options' vAlign='bottom'>
        <Part name='kind' label='Field type' display={OptionText} edit={Dropdown} options={kindOptions} />
        {question.kind == CfKind.Choice || question.kind == CfKind.MultipleChoice 
          ? <Part name='choices' label='Choices' validators={hasChoice} display={{format:choicesToString}} edit={<Dropdown multiple tags additions width='unset' placeholder='Start typing to add a choice' />} none='N/A' />
          : ''}
        {//labelProperty={null} below forces the normal checkbox behavior of having its own label to not have a label, and use the Parts normal label
        }
        <Part name='stage' label='Ask at checkout' component={CheckboxField} labelProperty={null} format={stageToBoolean} parse={booleanToStage} />
        <Part name='required' label='Required' component={CheckboxField} labelProperty={null} />
      </Section>
      <Section name='description' label='Description' placeholder='Optional description' edit={TextArea} autoSize />
      <Collapsible label='Advanced' initialOpen={!!(question.files?.length || question.filterCourseKinds?.length || question.filterCourses?.length)}>
        <Section name='files' label='Supporting files' component={FileUploads} none='N/A' description="Optional - upload any files your customers might need to answer this question" />
        {props.ownerType == CfOwnerKind.Season &&
        <>
          <Section name='filterCourseKinds' label='Show this question for specific activity types' component={<CourseKindPicker flex='unset!important' />} multiple />
          <Section name='filterCourses' label='Show this question for specific activities' component={CoursePicker} multiple queryVars={{seasonId: props.ownerId}} valueType='id' />
        </>
        }
      </Collapsible>
    </FormContent>
  }

  function choicesToString(choices:string[]) {
    return (choices || []).join(', ');
  }

  function stageToBoolean() {
    return question.stage == CfStage.PreCheckout;
  }

  function booleanToStage(checkout:boolean) {
    return checkout ? CfStage.PreCheckout : CfStage.Later;
  }

  function renderDisplay() {
    return <CfQuestionInput question={question} mode='author-preview' />
  }

  return render();
}

export const kindOptions = [{
  label: 'Free text',
  value: CfKind.MultipleLines
}, {
  label: 'Single choice',
  value: CfKind.Choice
}, {
  label: 'Multiple choice',
  value: CfKind.MultipleChoice
}, {  
  label: 'Checkbox',
  value: CfKind.Confirmation
}, {  
  label: 'File upload',
  value: CfKind.Upload
}]

function hasChoice(value:string[], fieldInfo:FieldInfo<CfQuestion>) {
  if ((fieldInfo.record.kind != CfKind.Choice && fieldInfo.record.kind != CfKind.MultipleChoice) || value?.length > 0) {
    return true;
  }

  return "Please enter at least one choice";
}