import { isNullOrUndefined } from 'util';
import { isEqual, cloneDeepWith, last, set, flatten, debounce, isEmpty, omitBy } from 'lodash-es';

import { assignId } from '../datatable';
import { ErrorWithPath, ErrorPathSegment, ErrorPathSegmentType, errorHasPath, errorToString } from '../error';
import { logger, isPromise } from '../utils'

import { FieldModel, FieldMap, FieldValidator, FieldValidatorResult } from "./FieldModel";
import { FormModelOptions, FormValidateOptions, FormModelSubscriber, FieldInfo, FormModel, FormSubmitHandler, UpdateOptions, UpdateType, FormResetOptions, FormResetHandler } from './FormModel';
import { required, isRequiredValidator } from './validators';

export interface FieldMetadata<T> {
  touched?: boolean;
  errors?: string[];
}
// FieldMetadatas is stored on the parent value of the field
// for flat form structures with no nesting objects, metadata
// is stored on formData._values.
export type FieldMetadatas<T> = {
  [P in keyof T]?: FieldMetadata<P>;
};

export class FormModelImpl<T> implements FormModel<T> {
  valid:boolean;
  validateRequired:boolean;
  fields:FieldMap<T, keyof T> = {};
  initialValues?: Partial<T>;
  _values?: T;
  _metadataMap = new WeakMap();
  _errors?: string[];
  subscribers:FormModelSubscriber<T, keyof T>[] = [];
  dirty?: boolean;
  submitted?: boolean;
  indexPaths:boolean;
  updateType:UpdateType;
  updateTimeout:any;
  onReset?:FormResetHandler<T>;
  version = 0;
  alwaysSave;
  assignIds?:boolean;
  showErrorsOnTouchedFields?:boolean;
  subforms?:Set<FormModel>;
  removedProp?:string;

  constructor(options?:FormModelOptions) {
    this.validateRequired = options?.validateRequired ?? true;
    this.indexPaths = options?.indexPaths;
    this.alwaysSave = options?.alwaysSave;
    this.assignIds = options?.assignIds ?? true;
    this.showErrorsOnTouchedFields = options?.showErrorsOnTouchedFields;
  }

  get options():FormModelOptions {
    return {validateRequired:this.validateRequired, indexPaths: this.indexPaths, alwaysSave: this.alwaysSave, assignIds: this.assignIds, showErrorsOnTouchedFields: this.showErrorsOnTouchedFields};
  }

  get values() {
    return this._values;
  }

  get empty() {
    return isFormEmptyOrDefault(this.values, this.initialValues);
  }

  get errors() {
    return this._errors;
  }

  set errors(value:string[]) {
    this._errors = value;
    this.updateSubscribers('metadata');
  }

  subscribe(subscriber:FormModelSubscriber<T, keyof T>):void {
    this.subscribers.push(subscriber);
  }

  unsubscribe(subscriber:FormModelSubscriber<T, keyof T>):void {
    const pos = this.subscribers.indexOf(subscriber);

    if (pos == -1) {
      return;
    }

    this.subscribers.splice(pos, 1);

    if (this.subscribers.length == 0) {
      this.clearSubscriberUpdate(true);
    }
  }

  updateSubscribers(type:'value' | 'metadata', path?:string) {
    if (!this.subscribers.length) {
      return;
    }

    const isValue = type == 'value';
    const updateType:UpdateType = this.updateType = this.updateType || {};
    
    updateType.reset = updateType.reset || !path;
    updateType.anyValue = updateType.anyValue || isValue;
    updateType.anyMetadata = updateType.anyMetadata || !isValue;
    updateType.fields = updateType.fields || {};

    if (path) {
      updateType.fields[path as string] = updateType.fields[path as string] || {};
      updateType.fields[path as string].value = updateType.fields[path as string].value || isValue;
      updateType.fields[path as string].metadata = updateType.fields[path as string].metadata || !isValue;
    }

    this.clearSubscriberUpdate(false);

    this.updateTimeout = setTimeout(() => {
      const updateType = this.updateType;
      this.updateType = null;
      this.updateTimeout = null;

      const subscribers = this.subscribers.slice();

      subscribers.forEach(subscriber => {
        // updates to fields can cause a field to get removed
        // while we are processing the updates, so we have to
        // check and see the subscriber is in the list
        // this will result in a n^2 check but should be ok
        // because the number of fields in a form tends to be low
        if (this.subscribers.indexOf(subscriber) != -1) {
          subscriber(this as FormModel<T>, updateType);
        }
      })
    }, 100);
  }

  private clearSubscriberUpdate(clearUpdateType:boolean) {
    if (this.updateTimeout) {
      clearTimeout(this.updateTimeout);
      this.updateTimeout = null;
    }

    if (clearUpdateType) {
      this.updateType = null;
    }
  }

  private getOrCreateParentField(parents:(string | number)[], create:boolean = true):{parent:FieldModel<T>, parentFields:FieldMap<T, keyof T>} {
    parents = this.removeIndexPaths(parents)

    let parent = null;
    let parentFields = this.fields;

    while (parents.length) {
      const name = parents.shift() as keyof T;

      if (!parentFields[name]) {
        if (!create) {
          return null;
        }

        parentFields[name] = {name, fields:{}, count: 0, parent, implicit: true};

        if (parent) {
          parent.count += 1;
        }
      }

      if (!parentFields[name].fields) {
        parentFields[name].fields = {};
      }

      parent = parentFields[name];
      parentFields = parentFields[name].fields;
    }

    return {parent, parentFields};
  }

  private getFieldInternal<P extends keyof T>(parents:(string | number)[], name:P):FieldModel<T, P> {
    const path = this.removeIndexPaths(parents.concat([name as string]));
    parents = path.slice(0, path.length - 1);
    name = path[path.length - 1] as P;

    const parentField = this.getOrCreateParentField(parents, false)?.parentFields;

    return parentField?.[name] as FieldModel<T, P>;
  }

  addField<P extends keyof T>(parents:(string | number)[], field:FieldModel<T, P>, validate?:boolean):FieldModel<T, P> {
    const normalized = normalizePath(parents, field.name);
    const parentInfo = this.getOrCreateParentField(normalized.parents);
    const parentField = parentInfo.parentFields;
    const parent = parentInfo.parent;

    if (parentField[normalized.name]) {
      // if the field already existed, and you are calling add field again
      // whatever you pass in will take precendence for the various props
      parentField[normalized.name].implicit = false;

      parentField[normalized.name].validators = field.validators;
      parentField[normalized.name].required = field.required;
      parentField[normalized.name].onChange = field.onChange;

      field = parentField[normalized.name];
      field.count += 1;
    }
    else {
      field = {...field, name: normalized.name, implicit: false};

      parentField[normalized.name] = field;
      parentField[normalized.name].count = 1;
      parentField[normalized.name].parent = parent as any;
    }

    this.compositeValdiators(field);

    // need to add ref count to the parent
    // for each child because the order in
    // which children and parents are removed
    // is not predictable (because reacts sometimes
    // calls parent useEffect before children unmount)

    if (parent) {
      parent.count += 1;
    }

    if (validate || validate === undefined) {
      this.debouncedValidate();
    }

    return field;
  }

  private compositeValdiators<P extends keyof T>(field:Partial<FieldModel<T, P>>) {
    field._validators = undefined;

    if (!field.required && !field.validators) {
      return;
    }

    field._validators = field.validators === undefined || field.validators === null 
      ? []
      : Array.isArray(field.validators)
        ? field.validators.slice()
        : [field.validators]

    if (field.required) {
      if (field._validators.findIndex(fn => isRequiredValidator(fn)) == -1) {
        field._validators.push(required);
      }
    }
  }

  removeField<P extends keyof T>(parents:(string | number)[], field:FieldModel<T, P> | P):void {
    const fieldName = typeof field == 'object' ? field.name : field;
    const normalized = normalizePath(parents, fieldName);
    const parentInfo = this.getOrCreateParentField(normalized.parents);
    const parentField = parentInfo.parentFields;

    parentField[normalized.name].count -= 1;

    if (parentField[normalized.name].count == 0) {
      delete parentField[normalized.name];

      const parents = this.removeIndexPaths(normalized.parents);

      if (parents.length > 0) {
        const parentName = last(parents);
        const parentParents = parents.slice(0, parents.length - 1);
        this.removeField(parentParents, parentName as keyof T);
      }
    }
  }

  getField<P extends keyof T>(parents:(string | number)[], name:P):FieldModel<T, P> {
    const normalized = normalizePath(parents, name);
    return this.getFieldInternal(normalized.parents, normalized.name);
  }

  updateField?<P extends keyof T>(parents:(string | number)[], name:P, field:Partial<Omit<FieldModel<T, P>, 'name'>>):FieldModel<T, P> {
    const normalized = normalizePath(parents, name);
    const parentField = this.getOrCreateParentField(normalized.parents).parentFields;

    if (!parentField[normalized.name]) {
      throw new Error("Field doesn't exist:" + [...normalized.parents, normalized.name].join('.'));
    }

    const existingField = parentField[normalized.name] as FieldModel<T, P>;
    existingField.implicit = false;

    let revalidate = false;
    let validatorsChanged = false;

    if (field.validators !== undefined && !isEqual(field.validators, existingField.validators)) {
      existingField.validators = field.validators;
      revalidate = true;
      validatorsChanged = true;
    }

    if (field.required !== undefined && (field.required !== existingField.required || validatorsChanged)) {
      existingField.required = field.required;
      revalidate = true;
      validatorsChanged = true;
    }

    if (field.disabled !== undefined && field.disabled !== existingField.disabled) {
      existingField.disabled = field.disabled;
      revalidate = true;
    }

    if (field.readOnly !== undefined && field.readOnly !== existingField.readOnly) {
      existingField.readOnly = field.readOnly;
      revalidate = true;
    }

    if (field.onChange !== undefined) {
      existingField.onChange = field.onChange;
    }

    if (validatorsChanged) {
      this.compositeValdiators(existingField);      
    }

    if (revalidate) {
      this.debouncedValidate();
      this.updateSubscribers('metadata', normalized.path.join('.'));
    }

    return existingField;
  }

  resetFields(fields:FieldModel<T, keyof T>[], validate?:boolean):void {
    this.debouncedValidate.cancel();

    this.fields = {};
    fields.forEach(field => this.addField([], field as any, false));

    if (validate) {
      this.debouncedValidate();
    }

    if (this.onReset) {
      this.onReset(this);
    }
  }

  getInfo<P extends keyof T>(parents:(string | number)[], fieldName: P, defaultValue?: T[P]):FieldInfo<T, P> {
    const normalized = normalizePath(parents, fieldName);
    const values = getValues(this.values, normalized.path, defaultValue);
    const field = this.getFieldInternal(normalized.parents, normalized.name);

    const info = {
      form: this,
      name: normalized.name,
      value: values[values.length - 1],
      values,
      record: values[values.length - 2],
      parents: normalized.parents,
      touched: this.isFieldTouched(normalized.parents, normalized.name),
      errors: this.getErrorsInternal(normalized.parents, normalized.name, true),
      required: field?.required,
      boolean: field?.boolean,
      disabled: field?.disabled,
      readOnly: field?.readOnly,
      field
    };
  
    return info;
  }

  getValueInternal<P extends keyof T>(path:(string | number)[], defaultValue?: T[P]): T[P] {
    return get(this.values, path, defaultValue);
  }

  getValueWithMetadataInternal<P extends keyof T>(path:(string | number)[], defaultValue?: T[P]): T[P] {
    return get(this._values, path, defaultValue);
  }

  getValue<P extends keyof T>(parents:(string | number)[], fieldName: P, defaultValue?: T[P]): T[P] {
    const normalized = normalizePath(parents, fieldName);

    if (!normalized.name) {
      throw new Error('Missing field name');
    }

    return this.getValueInternal(normalized.path, defaultValue);
  }
  
  touch<P extends keyof T>(parents:(string | number)[], fieldName: P):void {
    const normalized = normalizePath(parents, fieldName);

    if (!normalized.name) {
      throw new Error('Missing field name');
    }
  
    this.setFieldMetadata(normalized.parents, normalized.name, 'touched', true);
  }
  
  private isFieldTouched<P extends keyof T>(parents:(string | number)[], fieldName: P) {
    const fieldMetadata: FieldMetadata<P> = this.getFieldMetadata(parents, fieldName);
  
    return fieldMetadata && fieldMetadata.touched;
  }
  
  handleErrors(errors:ErrorWithPath[], clearPrevious?:boolean):ErrorWithPath[] {
    if (clearPrevious || clearPrevious === undefined) {
      this.clearErrors();
    }

    if (!errors.length) {
      this.updateSubscribers('metadata');
      return [];
    }

    const unhandled:ErrorWithPath[] = [];

    for (let error of errors) {
      if (!this.applyError(error)) {
        unhandled.push(error);
      }
    }

    this._errors = (this._errors || []).concat(unhandled.map(errorToString));
    this.submitted = this.submitted || this._errors.length > 0;
    this.valid = false;
    this.updateSubscribers('metadata');

    return [];
  }

  applyError(error:ErrorWithPath) {
    if (!this.errorPathInForm(error)) {
      return false;
    }

    // walk the path in the error until the end of the
    // path or until the path has no match in our values 
    // or field (the reason we check both is because often
    // values default to undefined, especially string values)
    // so that we can assign the error to the deepest matching
    // field/value

    let value:any = this._values;
    let field = {fields: this.fields} as FieldModel<T>;
    let pathToValue = [];

    for (let segment of error.path) {
      const isArray = Array.isArray(value);
      const propOrIndex = this.propOrindexFromErrorSegment(segment, value);
      const hasMoreSegments = field.fields && pathToValue.length + 1 < error.path.length;
      const childFieldCount = Object.values(field.fields || {}).length;

      // in order to continue the traversal down:
      //
      //  - if value is an array, the field must have child fields (else nothing
      //    can hold the error, even if its a row level error) and the index
      //    must be in the array, and the path must point to a valid path
      const validArraySegment = isArray && propOrIndex in value && (hasMoreSegments || childFieldCount > 0)

      //  - if value is not an array - the prop name must exist in the child fields
      const validPathSegment = (!isArray && field.fields && propOrIndex in field.fields);

      if (!validArraySegment && !validPathSegment) {
        logger.log('applyError:', segment, 'of', error.path, 'not found in', value, field.fields);
        break;
      }

      field = isArray ? field : field.fields[propOrIndex as keyof T];
      pathToValue.push(propOrIndex);
      value = value[propOrIndex as keyof T];

      if (value === undefined || value === null) {
        if (!field.implicit) {
          break;
        }
        else {
          return false
        }
      }
    }

    if (field.implicit) {
      return false;
    }

    const updateError = {...error, path: error.path.slice(pathToValue.length)};
    const fieldName = pathToValue.pop();
    const existingErrors = this.getErrors(pathToValue, fieldName as keyof T, false);
    this.setFieldMetadata(pathToValue, fieldName as keyof T, 'errors', existingErrors.concat([errorToString(updateError)]));
    this.setFieldMetadata(pathToValue, fieldName as keyof T, 'touched', true);

    // if showErrorsOnTouchedFields is false and submitted is false
    // the error will be hidden, so we force them to show because if applyErrors
    // is being called there's a good chance that some part of the form was submitted
    // if not the entire form
    this.submitted = true;

    return true;
  }

  errorPathInForm(error:ErrorWithPath) {
    return errorHasPath(error) && this.fields[error.path[0].value as keyof T];
  }

  propOrindexFromErrorSegment(segment:ErrorPathSegment, value:any) {
    let index:string;

    if (segment.type == ErrorPathSegmentType.property || segment.type == ErrorPathSegmentType.index) {
      index = segment.value
    }
    else
    if (Array.isArray(value)) {
      index = value.findIndex(item => item.id == segment.value).toString();
    }

    return index;
  }

  setErrors<P extends keyof T>(parents:(string | number)[], fieldName: P, errors:string[]):void {
    const normalized = normalizePath(parents, fieldName);
    const field = this.getFieldInternal(normalized.parents, normalized.name);
    
    if (!field || !normalized.name) {
      throw new Error('Missing field name');
    }
  
    this.setFieldMetadata(normalized.parents, normalized.name, 'touched', true);
    this.setFieldMetadata(normalized.parents, normalized.name, 'errors', errors);
  }

  setValue<P extends keyof T>(fieldName: P, fieldValue: T[P], options?:UpdateOptions):void;
  setValue<P extends keyof T>(parents:(string | number)[], fieldName: P, fieldValue: T[P], options?:UpdateOptions):void;
  setValue<P extends keyof T>(parentsOrField:(string | number)[] | P, fieldNameOrValue: P | T[P], fieldValueOrOptionsOrNothing?: T[P] | UpdateOptions, optionsOrNothing?:UpdateOptions) {
    const isArray = Array.isArray(parentsOrField);
    const parents = isArray ? parentsOrField as (string | number)[] : [];
    const fieldName = isArray ? fieldNameOrValue as P : parentsOrField as P;
    const fieldValue = (isArray ? fieldValueOrOptionsOrNothing : fieldNameOrValue) as T[P];
    const options = (isArray ? optionsOrNothing : fieldValueOrOptionsOrNothing) as UpdateOptions;

    return this.setValueInternal(parents, fieldName, fieldValue, false, options);
  }

  setValueInternal<P extends keyof T>(parents:(string | number)[], fieldName: P, fieldValue: T[P], settingMultipleValues:boolean, options?:UpdateOptions) {
    const normalized = normalizePath(parents, fieldName);
    const results:any[] = [];

    if (!normalized.name) {
      throw new Error('Missing field name');
    }

    const existingValue = this.getValueInternal(normalized.path);
  
    // some controls erroneously send change events when going
    // from undefined to null.  ignore these, as it makes it look
    // like the field value changed when it didn't really.
    if (isNullOrUndefined(existingValue) && isNullOrUndefined(fieldValue)) {

      return results;
    }
  
    if (compareFormValues(existingValue, fieldValue)) {
      return results;
    }
  
    this.version += 1;
    
    // occassionally values are set for fields that haven't been defined
    // such as id, so we support that by allowing a null field for a bit

    const field = this.getFieldInternal(normalized.parents, normalized.name);

    if (!options?.bypassDisabled && (field?.disabled || field?.readOnly)) {
      logger.error('attempting to set value on disabled field', normalized.path, fieldValue);
      return results;
    }

    if (!this._values) {
      this._values = {} as T;
    }

    // clone the incoming value because it might be something being used 
    // elsewhere (such as a default value)
    fieldValue = cloneWithoutId(fieldValue);
    this.migrateMetadata(this.getValueWithMetadataInternal(normalized.path), fieldValue);

    if (field && (field.assignIds || field.assignIds === undefined) && (this.assignIds || this.assignIds === undefined)) {
      assignId(fieldValue);
    }

    set(this, `_values.${normalized.path.join('.')}`, fieldValue);

    const wasDirty = this.dirty;
    const dirty = options?.dirty || options?.dirty === undefined;
    this.dirty = this.dirty || dirty;

    const displayableErrors = this.getErrorsInternal(normalized.parents, normalized.name, true);
    const errors = this.getErrorsInternal(normalized.parents, normalized.name, false);
  
    // if errors exist but aren't displaying, just remove
    // them because touch will show them immediately, possibly
    // before validation will clear them, and validation
    // will generate new errors if needed
    //
    // even if touch = false, it's possible for a ui controls
    // focus to be changed, resulting in a touch field call,
    // possibly before the validate runs below, so no
    // matter what, when we change a field value, we clear
    // non-displayable errors to avoid a flash of an error.
    // the validate will recreate them as needed.
    if (displayableErrors.length == 0 && errors.length) {
      this.setFieldMetadata(normalized.parents, normalized.name, 'errors', []);
    }

    if (options?.touch) {
      this.setFieldMetadata(normalized.parents, normalized.name, 'touched', true);
    }
    
    const info = this.getInfo(normalized.parents, normalized.name);

    if (!options?.bypassOnChange && field?.onChange) {
      results.push(field.onChange(fieldValue, info, settingMultipleValues, existingValue as any));
    }

    const namedParents = this.removeIndexPaths(normalized.parents);

    for (let pos = namedParents.length - 1; pos >= 0; --pos) {
      const parents = pos == 0 ? [] : namedParents.slice(0, pos);
      const name = namedParents[pos] as keyof T;
      const field = this.getFieldInternal(parents, name);

      if (!options?.bypassOnChange && field?.onChange) {
        results.push(field.onChange(fieldValue, info, settingMultipleValues, existingValue));
      }
    }

    if (dirty) {
      this.updateSubscribers('value', normalized.path.join('.'));
    }
    
    this.possibleResetValues(parents, fieldName, existingValue, results);
    this.debouncedValidate();

    return results;
  }

  // this will restore previous value to a form field if a change handler returns strictly false
  
  async possibleResetValues<P extends keyof T>(parents:(string | number)[], name: P, originalValue: T[P], promises:Promise<boolean>[]):Promise<boolean> {
    if (!promises || !promises.length) {
      return;
    }

    const results = await Promise.all(promises);
    const failed = results.some(result => result === false);

    if (failed) {
      this.setValueInternal(parents, name, originalValue, false, {dirty: false, touch: false, bypassOnChange: true, bypassDisabled: true});
    }
  }

  setValues(_values:Partial<T>, options?: UpdateOptions):void;
  setValues(parents:(string | number)[], _values:Partial<T>, options?: UpdateOptions):void;
  setValues(parentsOrValues:(string | number)[] | Partial<T>, valuesOrOptions?:Partial<T> | UpdateOptions, optionsOrNothing?:UpdateOptions):void {
    const isArray = Array.isArray(parentsOrValues);
    const parents = isArray ? parentsOrValues as (string | number)[] : [];
    const values = isArray ? valuesOrOptions as Partial<T> : parentsOrValues as Partial<T>;
    const options = isArray ? optionsOrNothing as UpdateOptions : valuesOrOptions as UpdateOptions;

    for (const field in values) {
      this.setValueInternal(parents, field, values[field], true, options);
    }
  }

  // performs form-wide and field validations
  debouncedValidate = debounce(async <T>(): Promise<boolean> => {
    return this.validate();
  }, 10);
  
  // performs form-wide and field validations
  async validate(options?:FormValidateOptions): Promise<boolean> {
    const promises: (boolean | Promise<boolean>)[] = this.validateArrayOrObject([], [], this.fields, options);
    const results = await Promise.all(promises);
    const failed = results.length != 0 && results.findIndex(result => result == false) != -1;
    this.valid = !failed;

    return this.valid;
  }

  validateSync(options?:FormValidateOptions):boolean {
    const promises: (boolean | Promise<boolean>)[] = this.validateArrayOrObject([], [], this.fields, options);
    const results = promises.filter(result => typeof result == 'boolean') as boolean[];
    const failed = results.length != 0 && results.findIndex(result => result == false) != -1;
    this.valid = !failed;

    return this.valid;
  }
  
  // - field parents is the dotted path to get to this field definition
  // - value parents is the dotted path to get to this value
  //
  // the difference is that for array properties they will have
  // indexes in their paths to the value, but those indexes
  // will not be present in the field path

  private validateArrayOrObject(fieldParents:(string | number)[], valueParents:(string | number)[], fields:FieldMap<T, keyof T>, options:FormValidateOptions): (boolean | Promise<boolean>)[] {
    const promises: (boolean | Promise<boolean>)[] = [];
    const value = this.getValueInternal(valueParents);

    if (Array.isArray(value)) {
      for (let pos = 0; pos < value.length; ++pos) {
        promises.push(...this.validateArrayOrObject(fieldParents, valueParents.concat([pos]), fields, options));
      }
    }
    else {
      const kept = !this.removedProp || !value || (value as any)[this.removedProp] !== true;

      if (kept) {
        promises.push(...this.validateObject(fieldParents, valueParents, fields, options));
      }
    }

    return promises;
  }

  private validateObject(fieldParents:(string | number)[], valueParents:(string | number)[], fields:FieldMap<T, keyof T>, options:FormValidateOptions): (boolean | Promise<boolean>)[] {
    const promises:(boolean | Promise<boolean>)[] = [];

    for (const fieldName of getKeys(fields)) {
      const field = fields[fieldName];
      const validators = this.getFieldValidators(fieldParents, fieldName, options);

      if (validators.length) {
        promises.push(this.validateField(fieldParents, valueParents, validators, fieldName));
      }
      else 
      if (this.getFieldMetadata(valueParents, fieldName).errors?.length) {
        this.setFieldMetadata(valueParents, fieldName, 'errors', []);
      }

      if (field.fields) {
        const childFieldParents = fieldParents.concat([fieldName as string]);
        // if coming from an array, validateArrayOrObject already added the value path
        const childValueParents = !isFinite(fieldName as number)? valueParents.concat([fieldName as string]) : valueParents.slice();
        promises.push(...this.validateArrayOrObject(childFieldParents, childValueParents, fields[fieldName].fields, options));
      }
    }

    return promises;
  }

  // validation currently runs all the time regardless of whether a field
  // has been touched, because we dont rerun validation on field touch
  private validateField(fieldParents:(string | number)[], valueParents:(string | number)[], validators:FieldValidator<T, keyof T>[], fieldName: keyof T): (boolean | Promise<boolean>) {
    const fieldInfo = this.getInfo(valueParents, fieldName);
    const resultsOrPromises = validators.map(validator => validator(fieldInfo.value, fieldInfo));
    const hasPromise = resultsOrPromises.find(isPromise) != null;

    // we can return immediately if there's nothing async
    return !hasPromise
      ? this.validateFieldFinish(fieldParents, valueParents, fieldName, resultsOrPromises as FieldValidatorResult[])
      : Promise.all(resultsOrPromises).then(results => {
          return this.validateFieldFinish(fieldParents, valueParents, fieldName, results);
        });
  }

  private validateFieldFinish(fieldParents:(string | number)[], valueParents:(string | number)[], fieldName: keyof T, results: FieldValidatorResult[]) {
    const errors = flatten(results.filter(result => typeof result == 'string' || Array.isArray(result)) as (string | string[])[]);
    const fieldMetadata = this.getFieldMetadata(valueParents, fieldName);
    const hasErrors = errors.length != 0;
    const hadErrors = fieldMetadata && fieldMetadata.errors && fieldMetadata.errors.length;
  
    if (!hadErrors && !hasErrors) {
      return true;
    }

    this.setFieldMetadata(valueParents, fieldName, 'errors', errors);
  
    return false;
}
     
  private getFieldValidators<P extends keyof T>(parents:(string | number)[], fieldName: P, options?:FormValidateOptions): FieldValidator<T, P>[] {
    if ((fieldName as string).indexOf('.') != -1) {
      throw new Error('Field names can not contain periods');
    }

    const field:FieldModel<T, P> = this.getField(parents, fieldName);

    return options?.ignoreDisabled || (!field.disabled && !field.readOnly) 
      ? toArray(field._validators).filter(validator => this.validateRequired || options?.validateRequired || !isRequiredValidator(validator))
      : [];
  }
  
  private getMetadatasForValues(_values: Partial<T>): FieldMetadatas<T> {
    return this._metadataMap.get(_values);
  }
  
  private getFieldMetadata<P extends keyof T>(parents:(string | number)[], fieldName: P, def: FieldMetadata<P> = { errors: [] }): FieldMetadata<P> {
    const normalized = normalizePath(parents, fieldName);

    if (!normalized.name) {
      throw new Error('Missing field name');
    }
  
    const parentValue = get(this._values, normalized.parents);
  
    if (!parentValue) {
      return def;
    }
  
    const metadata = this.getMetadatasForValues(parentValue);
  
    if (!metadata) {
      return def;
    }
  
    return metadata[normalized.name] || def;
  }
  
  getErrors<P extends keyof T>(parents:(string | number)[], fieldName: P, displayableOnly = false): string[] {
    if (!fieldName) {
      throw new Error('Missing field name');
    }

    const normalized = normalizePath(parents, fieldName);

    return this.getErrorsInternal(normalized.parents, normalized.name, displayableOnly)
  }

  getErrorsInternal<P extends keyof T>(parents:(string | number)[], fieldName: P, displayableOnly = false): string[] {
    const fieldMetadata: FieldMetadata<P> = this.getFieldMetadata(parents, fieldName);
    const errors = fieldMetadata.errors || [];

    return !displayableOnly || this.submitted || (this.showErrorsOnTouchedFields && fieldMetadata.touched) ? errors : [];
  }

  getNestedErrors<P extends keyof T>(parents:(string | number)[], fieldName: P, displayableOnly?:boolean): string[] {
    const normalized = normalizePath(parents, fieldName);
    const errors = this.getErrorsInternal(normalized.parents, normalized.name, displayableOnly);
    const field = this.getFieldInternal(normalized.parents, normalized.name);
    const value = this.getValueInternal(normalized.path);

    if (field.fields && (value !== undefined && value !== null)) {
      const childFields = getKeys(field.fields);

      if (Array.isArray(value)) {
        for (let childPos = 0; childPos < value.length; ++childPos) {
          errors.push(...this.getNestedErrors(normalized.parents, (normalized.name + '.' + childPos) as P, displayableOnly));
        }
      }
      else {
        for (let childFieldName of childFields) {
          errors.push(...this.getNestedErrors(normalized.parents, (normalized.name + '.' + childFieldName) as P, displayableOnly));
        }
      }
    }

    return errors;
  }

  fieldValid<P extends keyof T>(parents:(string | number)[], fieldName: P):boolean {
    try {
      return this.getNestedErrors(parents, fieldName).length == 0;
    }
    catch(e) {
      return false;
    }
  }

  private setFieldMetadata<P extends keyof T>(parents:(string | number)[], fieldName: P, metadataProp: 'errors' | 'touched', value: any) {
    if (!fieldName) {
      throw new Error('Missing field name');
    }
  
    if (!this._values) {
      this._values = {} as T;
    }
  
    if ((fieldName as string).indexOf('.') != -1) {
      throw new Error('Field names can not contain periods');
    }

    let parentValue = get(this._values, parents);
  
    if (!parentValue) {
      // note that this is incorrect because this could
      // be meant to hold array values, but we have no way of knowing that
      parentValue = {};
      set(this._values as any, parents, parentValue);
    }
  
    let metadatas: FieldMetadatas<T> = this.getMetadatasForValues(parentValue);
    if (!metadatas) {
      metadatas = {};
      this._metadataMap.set(parentValue, metadatas);
    }
  
    let metadata = metadatas[fieldName];
    if (!metadata) {
      metadata = metadatas[fieldName] = {};
    }
  
    if (!isEqual(metadata[metadataProp], value)) {
      metadata[metadataProp] = value;
      this.updateSubscribers('metadata', parents.concat(fieldName as string).join('.'));
    }
  }
    
  clearErrors() {
    this.errors = [];
    this.clearFieldErrors([], [], this.fields);
  }
  
  private clearFieldErrors(fieldParents:(string | number)[], valueParents:(string | number)[], fields:FieldMap<T, keyof T>) {
    const value = this.getValueInternal(valueParents);

    if (Array.isArray(value)) {
      for (let pos = 0; pos < value.length; ++pos) {
        if (this.getFieldMetadata(valueParents, pos.toString() as any).errors?.length) {
          this.setFieldMetadata(valueParents, pos.toString() as any, 'errors', []);
        }

        this.clearFieldErrors(fieldParents, valueParents.concat([pos]), fields)
      }
    }
    else {
      for (const fieldName of getKeys(fields)) {
        const field = fields[fieldName];

        if (this.getFieldMetadata(valueParents, fieldName).errors?.length) {
          this.setFieldMetadata(valueParents, fieldName, 'errors', []);
        }
    
        if (field.fields) {
          const childFieldParents = fieldParents.concat([fieldName as string]);
          // if coming from an array, validateArrayOrObject already added the value path
          const childValueParents = !isFinite(fieldName as number)? valueParents.concat([fieldName as string]) : valueParents.slice();
          this.clearFieldErrors(childFieldParents, childValueParents, fields[fieldName].fields)
        }
      }
    }
  }
  
  reset(options?:FormResetOptions<T>):void {
    this.debouncedValidate.cancel();

    if (options?.initialValues !== undefined) {
      this.initialValues = options.initialValues;
    }
    
    this.errors = undefined;
    this.submitted = undefined;
    this.dirty = options?.dirty;
    this._values = options?.values || cloneWithoutId(this.initialValues || {});
    this.version += 1;

    this.updateSubscribers('value');

    if (options === undefined || options?.validate === undefined || !!options?.validate) {
      this.validate();
    }

    if (this.onReset) {
      this.onReset(this);
    }
  }

  async presubmit(options:FormValidateOptions = {skipValidatingEmpty: false}) {
    if (options?.skipValidatingEmpty && this.empty) {
      return true;
    }

    let success;
    
    try {
      success = await this.validate(options);
    }
    finally {
      if (!this.submitted && options?.markSubmitted !== false) {
        this.submitted = true;
        // ensures untouched error fields start showing errors
        // and has to be done after validation has occurred
        // because the ui will update immediately but validations are async
        this.updateSubscribers('metadata');
      }
    }

    return success;
  }

  async presubmitAll(options?:{skipValidatingEmpty?:boolean, markSubmitted?:boolean}) {
    const result = await this.presubmit(options);

    if (!result) {
      return false;
    }

    if (!this.subforms?.size) {
      return true;
    }

    const subformResults = await Promise.all(Array.from(this.subforms).map(subform => subform.presubmitAll(options)));

    return subformResults.every(result => result);
  }

  async submit(onSubmit?:(form:FormModel<T>, ...args:any[]) => FormSubmitHandler, ...args:any[]):Promise<boolean> {
    const success = await this.presubmit();
    let result:boolean | any = false;

    if (!success) {
      return false;
    }

    if (!this.alwaysSave) {
      const hasRequiredFields = this.hasRequiredFields();

      if (!hasRequiredFields && !this.dirty) {
        return true;
      }

      if (hasRequiredFields && isEqual(this.initialValues, this.values) && !this.dirty) {
        return true;
      }
    }

    if (onSubmit) {
      result = await Promise.resolve(onSubmit(this, ...args));

      if (result === false) {
        return false;
      }
    }

    logger.log('submit handler returned non-false - clearing errors');

    // clone the values to the initialValues so that the form
    // is reset and does not look dirty (we dont rely on the
    // form user to reset the form after save)
    this.initialValues = cloneWithoutId(this.values);
    this.dirty = false;
    this.clearErrors();

    return result || true;
  }

  hasRequiredFields() {
    return hasRequired(this.fields);
  }

  // metadata is stored on the parents of individual fields
  // this works except when the parent is replaced.  this
  // generally only happens for array values.  when users of the form
  // get values of the form, the metadata is stripped off because its unexpected
  // that it's stored on the form values.  so this is a hack
  // that attempts to move the metadata over by looking for an
  // id property on the array items.  alternatively we could add 
  // special methods for updating array values (like removeArrayItem, addArrayItem)

  migrateMetadata(oldValue:any, newValue:any) {
    if (!Array.isArray(oldValue) || !Array.isArray(newValue) || !oldValue.length || !newValue.length) {
      return;
    }

    const metadatas = new Map();

    for (let value of oldValue) {
      if (value?.id) {
        metadatas.set(value.id, this._metadataMap.get(value));
      }
    }

    for (let value of newValue) {
      if (value?.id) {
        this._metadataMap.set(value, this._metadataMap.get(value) || metadatas.get(value.id));
      }
    }
  }

  removeIndexPaths(path:(string | number)[]) {
    if (this.indexPaths) {
      return path;
    }

    const digit = /^[0-9]/;
    return path.filter(parent => typeof parent == 'string' && !digit.test(parent)) as string[];
  }
}

// helper to properly type Object.keys, that should have already been typed by Typescript
function getKeys<T>(o: T): Array<keyof T> {
  return o ? <Array<keyof T>>Object.keys(o) : [];
}

function toArray<T>(o: T | T[]): T[] {
  return o === undefined || o === null ? [] : Array.isArray(o) ? o : [o];
}

export type CreateFormOptions<T> = {values?: Partial<T>, validate?:boolean, fields?:FieldModel<T, keyof T>[], onReset?:FormResetHandler<T>} & FormModelOptions;

export function createForm<T>(options?:CreateFormOptions<T>):FormModel<T> {
  let { values: initialValues, validate, fields, onReset, ...remaining} = options || {};
  validate = validate === undefined ? true : validate;
  fields = fields || [];

  const form = new FormModelImpl<T>(remaining);
  form.initialValues = initialValues;
  form.onReset = onReset;

  if (fields) {
    fields.forEach(field => form.addField([], field, false))
  }

  if (initialValues) {
    form.reset({initialValues, validate})
  }

  return form;
}

function get(o:any, fields:any[], defaultValue?:any) {
  for (let pos = 0; pos < fields.length; ++pos) {
    if (o === undefined || o === null) {
      return defaultValue;
    }

    o = o[fields[pos]];
  }

  return o === undefined ? defaultValue : o;
}

export function getValues(o:any, fields:any[], defaultValue?:any) {
  const values = [];
  for (let pos = 0; pos < fields.length; ++pos) {
    if (o === undefined || o === null) {
      break;
    }

    values.push(o);
    o = o[fields[pos]];
  }

  // if defaultValue is undefined and the value is null, we want to
  // use null since that can be considered an intentional value
  
  values.push(o === undefined || o === null ? defaultValue || o : o);

  return values;
}

function normalizePath<T, P = keyof T>(parents:(string | number)[], name:P) {
  // joining all parts then splitting deals with cases where parents have
  // dotted paths in them
  const parts = parents.concat(name as unknown as string).filter(p => !!p || p === 0).join('.').split('.')

  name = parts.pop() as unknown as P;
  parents = parts;

  const path = parents.slice();
  path.push(name as unknown as string);

  return {parents, name, path}
}

function cloneWithoutId(obj:any, skipId:boolean = false):any {
  // lodash cloneWith doesn't allow you to call the base
  // clone version and then modify the result, so we mimic
  // that by skipping over the value to be cloned but 
  // recursively calling our version of clone and then
  // modifying the result.
  
  const skip = obj;

  function customizer(value:any) {
    const clone = value === skip || (Number.isNaN(value) && Number.isNaN(skip)) ? undefined : cloneWithoutId(value, skipId);

    return clone;
  }

  const ret = cloneDeepWith(obj, customizer);

  if (skipId && ret && typeof ret.id !== undefined) {
    delete ret.id;
  }

  return ret;
}

function compareFormValues(a:any, b:any, skipId:boolean = false) {
  return isEqual(cloneWithoutId(a, skipId), cloneWithoutId(b, skipId));
}

function hasRequired<T>(fields: FieldMap<T, keyof T>): boolean {
  for (const fieldName in fields) {
    const field = fields[fieldName];

    if (field.required) {
      return true;
    }

    if (!isEmpty(field.fields)) {
      return hasRequired(field.fields);
    }
  }
  
  return false;
}

export function isFormEmpty<T>(values:T) {
  const nonEmptyFields = Object.keys(omitBy(values, isEmpty));
  const empty = nonEmptyFields.length == 0 || (nonEmptyFields.length == 1 && nonEmptyFields[0] == 'id');

  return empty;
}

export function isFormEmptyOrDefault<T>(values:T, defaults:T) {
  if (isFormEmpty(values)) {
    return true;
  }

  return compareFormValues(values, defaults, true);
}