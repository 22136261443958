import * as React from 'react';
import { map, omit } from 'lodash-es';

import { DiscountDefinitionFormat, DiscountKind, DiscountDefinitionInput } from 'app2/api';
import { Field, FormModel, InputField, Panel, RepeatingSection, useForm, DropdownField, FieldRendererProps, Tag, palette } from 'app2/components';
import { DiscountCombo } from 'app2/views';

import { OrganizerDiscountsSelections, organizerUpsertDiscounts, useOrganizerDiscountsQuery } from './gql';

type Discounts = OrganizerDiscountsSelections[];

interface FormValues {
  discounts: Discounts;
}

interface Props {
  ownerId: string;
  ownerType: 'season' | 'site';
}

export function Discounts(props: Props) {
  const { ownerId, ownerType } = props;
  const [result, reeexecuteDiscountsQuery] = useOrganizerDiscountsQuery({ variables: { ownerId, ownerType }, context: React.useMemo(() => ({ additionalTypenames: ['OrganizerDiscountDefinition'] }), []) });
  const discounts = result.data?.discountDefinitions || [];
  const form = useForm<FormValues>({ discounts }, [discounts]);

  function render() {
    return (
      <Panel
        icon="Tag"
        title="Discounts"
        subtitle="Each enrollment can only be paired with one discount code. If more than one discount is applied, we will automatically choose the one that offers the greatest savings."
        onOk={onOk}
        type="toggle"
        form={form}
      >
        <RepeatingSection
          name="discounts"
          add="Add discount"
          onRemove="archive"
          numbered={false}
          equalWidths
          defaultRecord={defaultRecord}
          fields={[
            <Field label="Code" name="code" required component={InputField} format={(val: string) => val.replace(/\s/g, '').toUpperCase()} />,
            <Field label="Status" name="active" required none={false} edit={{ ...DropdownField, placeholder: 'Select a status', options: statusOptions }} display={{ render: renderStatus, stretch: false }} />,
            <Field label="Type" name="kind" required none={false} edit={{ ...DropdownField, placeholder: 'Select a type', options: kindOptions }} display={{ render: renderKind, stretch: false }} />,
            <Field label="Amount off" required component={DiscountCombo} />,
            <Field name="usesCount" label="Used" width="40px" />,
            'remove'
          ]}
        />
      </Panel>
    );
  }

  function renderStatus(props: FieldRendererProps<boolean>) {
    const option = statusOptions.find(o => o.value === props.info.value);
    return <Tag label={option.label} bg={option.bg} icon={null} />;
  }

  function renderKind(props: FieldRendererProps<DiscountKind>) {
    return kindLabels[props.info.value as DiscountKind];
  }

  async function onOk(form: FormModel<FormValues>) {
    const discounts = extractInputs(form);
    const [success] = await organizerUpsertDiscounts({ variables: { ownerId, ownerType, discounts }, error: form });
    if (success) {
      reeexecuteDiscountsQuery();
    }
    return success;
  }

  function extractInputs(form: FormModel<FormValues>): DiscountDefinitionInput[] {
    return map(form.values.discounts, d => omit(d, ['usesCount']));
  }

  return render();
}

const defaultRecord = { code: '', active: true, kind: DiscountKind.Activity, format: DiscountDefinitionFormat.Percentage, amount: 0, usesCount: 0 };

const statusOptions = [
  { label: 'Active', value: true, bg: palette.secondary.lightGreen.hex },
  { label: 'Draft', value: false, bg: palette.secondary.lightYellow.hex }
];

const kindLabels = {
  [DiscountKind.Activity]: 'Code',
  [DiscountKind.Sibling]: 'Sibling (automatic)'
};

const kindOptions = Object.values(DiscountKind).map(k => ({ label: kindLabels[k], value: k }));
