import * as React from 'react'
import { truncate } from 'lodash-es';
import { useHistory } from 'react-router';

import { isImpersonatingUser, stopImpersonating } from 'app2/api'
import { HBox, MenuDropdown, MenuItem, NotificationManager, Text, BoxProps } from 'app/app2/components'

import { useCurrentUser } from '../user';

export function AccountMenu(props:BoxProps) {
  const { user } = useCurrentUser();
  const name = truncate(user?.firstName, {length: 20, omission: '.'}) + ' ' + truncate(user?.lastName, {length: 2, omission: '.'});
  const impersonating = isImpersonatingUser();

  const history = useHistory();

  function render() {
    return <MenuDropdown aria-label='Account' label={renderInitials()} {...props}>
      <Text text='subtitle2' mb='16px!important' textTransform='capitalize'>{name}</Text>
      <MenuItem label='Account settings' to='/user/account-settings' />
      <MenuItem label='Invitations' to='/user/invites' />
      {user?.roles?.admin ? <MenuItem label='Go to admin' to='/admin2' /> : ''}
      {impersonating ? <MenuItem label='Stop impersonating' onClick={handleStopImpersonating} /> : ''}
      {user?.roles?.provider && <MenuItem label='Go to Provider profile' to='/provider' onClick={handleGoToProviderProfile} />}
      <MenuItem label='Messages' to='/user/messages' />
      <MenuItem label='Logout' to='/logout' />
    </MenuDropdown>
  }

  function renderInitials() {
    return <HBox hAlign='center' vAlign='center' height='30px' width='30px' borderRadius='15px' pt='1px' bg='brand' textTransform='uppercase' text='subtitle2' color='#fff!important' link>
      {(user?.firstName || '').charAt(0) + (user?.lastName || '').charAt(0)}
    </HBox>;
  }

  function handleStopImpersonating() {
    stopImpersonating();

    history.replace('/');
  }

  function handleGoToProviderProfile() {
    NotificationManager.success("You can now get to your provider settings through the left hand navigation, under the 'Provider' section.", {title:'Heads up', duration: 10000});
  }

  return render();
}
