import * as React from 'react';
import { debounce } from 'lodash-es';

import { CourseUtils, DiscountFormat, EnrollmentStatusFilter, authorizedCoursePreferenceKeys } from 'app2/api';
import { DataTable, DataTableColumn, Icon, Link, PercentField, useRef } from 'app2/components';

import { CourseSelections } from '../../generated';

import { EnrollmentsTable } from '../EnrollmentsTable';
import { useCourseEnrollmentCols } from '../useCourseEnrollmentCols';

import { BilledLaterAdvancedEnrollmentsSelections, enrollmentsSetAdditionalDiscount, useBilledLaterAdvancedEnrollmentsQuery } from './gql';
import { BillNowAction } from './BillNowAction';

const TABLE_PREFS_VERSION = '1';

interface Props {
  course: CourseSelections;
}

export function BilledLaterAdvanced(props: Props) {
  const cols = getCols();
  const tableRef = useRef<DataTable<BilledLaterAdvancedEnrollmentsSelections>>();

  function render() {
    if (!CourseUtils.hasBilledLaterRates(props.course?.rates) && !CourseUtils.hasBilledLaterEnrollments(props.course)) {
      return null;
    }

    return (
      <EnrollmentsTable<BilledLaterAdvancedEnrollmentsSelections>
        course={props.course}
        header={{ id:'billed-later', title: <>Billed later<Link ml='$20' text='body' to='billing#billed'>Billed<Icon name='ArrowUp' size='small' /></Link></>, units: 'purchases', editing: true, options: renderOptions() }}
        table={{ cols, ref: tableRef, none: 'No purchases', cellStyle: 'read-only', editable: true, atLeastOneEditableRow: false, sortFilterWhenEditable: true, appendable: false, onDataUpdate: onChangeAdditionalDiscount  }}
        view={{table:'course-billed-later-advanced'}}
        prefsVersion={TABLE_PREFS_VERSION}
        prefsKey={authorizedCoursePreferenceKeys.billedLaterAdvancedEnrollmentsTable}
        queryHook={useBilledLaterAdvancedEnrollmentsQuery}
      />
    );
  }

  function getCols() {
    const cols = useCourseEnrollmentCols<BilledLaterAdvancedEnrollmentsSelections>(baseCols, props.course, EnrollmentStatusFilter.BilledLaterAdvanced, false);

    return cols;
  }

  function renderOptions() {
    return [<BillNowAction course={props.course} />]
  }

  const onChangeAdditionalDiscount = debounce(async () => {
    const changes = tableRef.current.getChanges();
    const discounts = Object.keys(changes).map(enrollmentId => {
      const billedLater = changes[enrollmentId].item;

      return {
        enrollmentId,
        discount: !billedLater.additionalDiscount || !billedLater.additionalDiscount.rate
        ? null
        : {
          rate: billedLater.additionalDiscount.rate,
          format: DiscountFormat.Percentage,
        }
      }
    });

    if (!discounts.length) {
      return;
    }

    await enrollmentsSetAdditionalDiscount({variables:{discounts}});
  }, 1000);

  return render();
}

const baseCols = [
  'student.firstName',
  'student.lastName',
  'billingDescription',
  'rosterPeriod',
  'billPeriod',
  'billingDate',
  'discountAmount',
  'discountCodes',
  {
    ...PercentField,
    name: 'additionalDiscount.rate' as keyof BilledLaterAdvancedEnrollmentsSelections,
    label: 'One-time discount (%)',
    infoTip: "Apply a one-time percentage discount on top of existing discounts for this charge. This will not affect future charges.",
    width: 250,
    readOnly: false,
    placeholder: 'Enter discount %'
  } as DataTableColumn<BilledLaterAdvancedEnrollmentsSelections>,
  'priceTier',
  'balance',
  'student.grade',
  'groups'
];
