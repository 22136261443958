import * as React from 'react'

import { CompanyPage } from '../shared'

import { Stripe } from './Stripe'

export function PaymentTools() {
  function render() {
    return <CompanyPage title='Payment tools'>
      <Stripe />
    </CompanyPage>
  }

  return render();
}