import * as Types from '../../../../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AddStudentMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  parentEmail?: Types.InputMaybe<Types.Scalars['String']>;
  studentName?: Types.InputMaybe<Types.Scalars['String']>;
  studentId?: Types.InputMaybe<Types.Scalars['ID']>;
  discount?: Types.InputMaybe<Types.DiscountInput>;
  kind: Types.PriceConfigKind;
  recurringPriceConfig?: Types.InputMaybe<Types.RecurringPriceConfigInput>;
  dropInPriceConfig?: Types.InputMaybe<Types.DropInPriceConfigInput>;
  configurableSeason?: Types.InputMaybe<Types.SeasonPriceConfigInput>;
}>;

export type AddStudentMutation = { enrollmentAdd?: { enrollments?: Array<{ id: string } | null | undefined> | null | undefined } | null | undefined };

export const AddStudentDocument = gql`
  mutation AddStudent(
    $id: ID!
    $parentEmail: String
    $studentName: String
    $studentId: ID
    $discount: DiscountInput
    $kind: PriceConfigKind!
    $recurringPriceConfig: RecurringPriceConfigInput
    $dropInPriceConfig: DropInPriceConfigInput
    $configurableSeason: SeasonPriceConfigInput
  ) {
    enrollmentAdd(
      id: $id
      parentEmail: $parentEmail
      studentName: $studentName
      studentId: $studentId
      discount: $discount
      kind: $kind
      recurringPriceConfig: $recurringPriceConfig
      dropInPriceConfig: $dropInPriceConfig
      configurableSeason: $configurableSeason
    ) {
      enrollments {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type AddStudentOptions = ExecMutationOptions<AddStudentMutationVariables>;

export function addStudent(options: AddStudentOptions) {
  return executeMutation<AddStudentMutation, AddStudentMutationVariables>(AddStudentDocument, options);
}
