import * as React from 'react';

import { Route, companyOrOrganizerMember } from 'app2/views/shared-public';
import { Search, Student } from 'app2/views/shared';

import { AccountSettings, Logout } from './account'
import { UserMessages } from './messages'
import { AddRole } from './add-role'
import { Invites } from './invites'

export const userRoutes = [
  <Route path="/user/account-settings" component={AccountSettings} exact role='logged-in' />,
  <Route path="/user/messages" component={UserMessages} role='logged-in' />,
  <Route path="/user/invites" component={Invites} role='logged-in' />,
  <Route path="/logout" component={Logout} role='logged-in' />,  
  <Route path="/signout" component={Logout} role='logged-in' />,  
  <Route path="/search" component={Search} role={companyOrOrganizerMember} />,
  <Route path="/student/:id" component={Student} role={companyOrOrganizerMember} />,
  <Route path="/add-role" component={AddRole} role='logged-in' />,
]

