import * as React from 'react';

import { HBox, Link, Text } from 'app2/components'
import { PrivatePage, PrivatePageProps } from 'app2/views/shared';

import { useCompanyQuery } from './useCompanyQuery';
import { useBasicCompanyQuery } from './generated';
import { CompanyDropdown } from './CompanyDropdown';

interface Props extends PrivatePageProps {
  showSiteSearchCTA?: boolean;
}

export function CompanyPage(props:Props) {
  const {showSiteSearchCTA, ...remaining} = props;
  const { company } = useCompanyQuery(useBasicCompanyQuery);

  function render() {
    return <PrivatePage above={renderSiteSearchCTA()} actions={<CompanyDropdown />} {...remaining} />;
  }

  function renderSiteSearchCTA() {
    if (!showSiteSearchCTA) {
      return;
    }

    return <HBox bg='successFill' border='solid 1px' borderColor='success' borderRadius='standard' p='$12' mb='$12' position='sticky' left={0} display={['none','flex']}>
      <Text text='body' flex={1}>Leverage our School Directory to bring your activities to more schools</Text>
      <Link to={`/directory/school/${company?.zipcode || ''}`} button='primary'>School directory</Link>
    </HBox>
  }

  return render();
}

CompanyPage.defaultProps = {
  showSiteSearchCTA: true
}
