import * as React from 'react'
import { AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';

import { axios } from 'app2/api';
import { Button, Link, Modal } from 'app2/components'

export async function downloadFile(axiosPromise: Promise<AxiosResponse<any>>, mimeType: string) {
  try {
    const { data, headers } = await axiosPromise;
    const filename = headers['content-disposition']
      .replace(/\\/g, '')
      .replace(/"/g, '')
      .split('filename=')[1];
    const file = new File([data], filename, { type: mimeType });

    saveAs(file, filename);
  }
  catch(e) {
    Modal.error('Error', e.message || e);
  }
}

// this should be moved to shared-public, along with downloadFile


// example usage:
// <DownloadIcon name='Download' url={`/orders/${props.info.record.id}/receipt`} mimeType='application/pdf' />} />
//
// OR
//
// <MyCompoany onClick={downloadHandler(`/orders/${props.info.record.id}/receipt`, 'application/pdf')} />} />
//
//

interface DownloadProps {  
  url:string;
  mimeType:string;
}

export function downloadHandler(url:string, mimeType:string):() => void
export function downloadHandler(urlOrProps:DownloadProps):() => void
export function downloadHandler(urlOrProps:string | DownloadProps, maybeMimeType?:string) {
  const url = typeof urlOrProps == 'string' ? urlOrProps : urlOrProps.url;
  const mimeType = typeof urlOrProps == 'string' ? maybeMimeType : urlOrProps.mimeType;

  return function() {
    return downloadFile(
      axios.get(url, {
        responseType: 'blob'
      }),
      mimeType
    )}

}

function createDownloadComponent<P>(comp:React.ComponentType<P>) {
  return function (props:DownloadProps & P) {
    const {url, mimeType, ...remaining} = props;

    return React.createElement(comp, {...remaining, onClick:downloadHandler(url, mimeType)} as unknown as P);
  }
}

export const DownloadButton = createDownloadComponent(Button);

//@ts-ignore
DownloadButton.defaultProps = {
  autoLoader: true
}


export const DownloadLink = createDownloadComponent(Link);

//@ts-ignore
DownloadLink.defaultProps = {
  autoLoader: true
}

