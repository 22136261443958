// a collection of utilties for using styled components
// and styled system together to create components with a lot
// of overridable styles
import React from 'react';
import styled from 'styled-components';
import { Property } from 'csstype';
import {
  system,
  css as styledSystemCss,
  SystemStyleObject,
  compose,
  space,
  SpaceProps,
  layout,
  LayoutProps,
  color,
  ColorProps,
  typography,
  TypographyProps,
  flexbox,
  FlexboxProps,
  background,
  BackgroundProps,
  border,
  BorderProps,
  position,
  PositionProps,
  shadow,
  ShadowProps
} from '../styled-system';

import { createComponentBase, ComponentBaseProps } from './ComponentBase'
import { EventLogData } from './EventLogger'
import { property } from './property';

// adding a new style property involves doing the following
//  1. create a style function that gets invoked in styled systems
//     template literal (or object).  there's various ways of doing that
//     such as https://styled-system.com/custom-props, and https://styled-system.com/variants
//     or https://github.com/yldio/styled-is
//
//  2. add the style function either to componentProps or
//     to a components template literal (or object)
//
//  3. add the style property definition to the components
//     typescript definition (ComponentProps)
//
//  4. add the property to the list of properties to exclude (baseExcludeProps)

// 1. style functions
// add properties not in the styled system base set of properties
const boxSizing = system({
  boxSizing: true
});

const cursor = system({
  cursor: true
});

const userSelect = system({
  userSelect: true
});

const textDecoration = system({
  textDecoration: true
});

const whiteSpace = system({
  whiteSpace: true
});

const wordBreak = system({
  wordBreak: true
});

const transition = system({
  transition: true
});

const textTransform = system({
  textTransform: true
});

const pointerEvents = system({
  pointerEvents: true
});

const overflowWrap = system({
  overflowWrap: true
});

const transform = system({
  transform: true
}); 

const transformOrigin = system({
  transformOrigin: true
}); 

const tooltipBorder = property('tooltipBorder', () => ({
  cursor:'pointer',
  borderBottom:'dashed',
  borderColor:'border' 
})); 

const outline = system({
  outline: true
}); 

const clipPath = system({
  clipPath: true
}); 

const objectFit = system({
  objectFit: true
}); 

const objectPosition = system({
  objectPosition: true
}); 

const caretColor = system({
  caretColor: true
}); 

// add the ability to have a css property
export function css(props: ComponentProps<any>) {
  return props.css ? styledSystemCss(props.css) : undefined;
}

// 2. consolidate style functions to be used in template literal (or object)
// consolidates all the styled properties to be used in the template literal (or object) except css
export const componentProps = compose(
  background,
  border,
  boxSizing,
  color,
  cursor,
  flexbox,
  layout,
  pointerEvents,
  position,
  shadow,
  space,
  textDecoration,
  textTransform,
  transition,
  typography,
  userSelect,
  whiteSpace,
  wordBreak,
  overflowWrap,
  transform,
  transformOrigin,
  tooltipBorder,
  outline,
  clipPath,
  objectFit,
  objectPosition,
  caretColor
);

// adds css to the list of all properties.  this is to be what has to be included in the template
// literal (or object) when creating a styled component.
export const componentStyles = ([
  componentProps,
  (props: any) => css(props)
] as unknown) as TemplateStringsArray[];

// 3. style typescript definition
// define the typescript type that specifies all the available properties on the styled components
export type CustomComponentProps =
  SpaceProps &
  LayoutProps &
  ColorProps &
  TypographyProps &
  FlexboxProps &
  BackgroundProps &
  BorderProps &
  PositionProps &
  ShadowProps & 
  ComponentBaseProps & {
    ref?: ((ref: any) => void) | React.MutableRefObject<any>; // Typed to support callback refs.
    boxSizing?: Property.BoxSizing | Property.BoxSizing[];
    css?: SystemStyleObject;
    cursor?: Property.Cursor | Property.Cursor[];
    userSelect?: Property.UserSelect | Property.UserSelect[];
    textDecoration?: Property.TextDecoration | Property.TextDecoration[];
    whiteSpace?: Property.WhiteSpace | Property.WhiteSpace[];
    wordBreak?: Property.WordBreak | Property.WordBreak[];
    transition?: Property.Transition | Property.Transition[];
    textTransform?: Property.TextTransform | Property.TextTransform[];
    pointerEvents?: Property.PointerEvents | Property.PointerEvents[]; 
    overflowWrap?: Property.OverflowWrap | Property.OverflowWrap[];
    transform?: Property.Transform | Property.Transform[];
    transformOrigin?: Property.Transform | Property.Transform[];
    tooltipBorder?:boolean;
    outline?: Property.Outline | Property.Outline[];
    clipPath?: string | string[];
    objectFit?: string | string[];
    objectPosition?: string | string[];
    // note that using the 'as' property can prevent any
    // ComponentBase properties from working
    as?:string | React.ComponentType<any>;
    // will log this event whenever the component is clicked
    clickEvent?:EventLogData;
    caretColor?:string;
  };

export type ComponentProps<T> = Omit<T, 'onClick'> & CustomComponentProps;

// 4. excluding properties from html
// list all the properties to exclude from being outputted in html
function arrayToObject(array: string[], base?: any): any {
  return array.reduce((o: any, prop: string) => {
    o[prop] = true;
    return o;
  }, base || {});
}

export const baseExcludePropsArray = [
  'alignContent',
  'alignItems',
  'alignSelf',
  'background',
  'backgroundColor',
  'backgroundImage',
  'backgroundPosition',
  'backgroundRepeat',
  'backgroundSize',
  'bg',
  'border',
  'borderBottom',
  'borderBottomColor',
  'borderBottomLeftRadius',
  'borderBottomRightRadius',
  'borderBottomStyle',
  'borderBottomWidth',
  'borderColor',
  'borderLeft',
  'borderLeftColor',
  'borderLeftStyle',
  'borderLeftWidth',
  'borderRadius',
  'borderRight',
  'borderRightColor',
  'borderRightStyle',
  'borderRightWidth',
  'borderStyle',
  'borderTop',
  'borderTopColor',
  'borderTopLeftRadius',
  'borderTopRightRadius',
  'borderTopStyle',
  'borderTopWidth',
  'borderWidth',
  'borderX',
  'borderY',
  'bottom',
  'boxShadow',
  'boxSizing',
  'color',
  'css',
  'cursor',
  'display',
  'flex',
  'flexBasis',
  'flexDirection',
  'flexGrow',
  'flexShrink',
  'flexWrap',
  'fontFamily',
  'fontSize',
  'fontStyle',
  'fontWeight',
  'height',
  'justifyContent',
  'justifyItems',
  'justifySelf',
  'left',
  'letterSpacing',
  'lineHeight',
  'm',
  'margin',
  'marginBottom',
  'marginLeft',
  'marginRight',
  'marginTop',
  'marginX',
  'marginY',
  'maxHeight',
  'maxWidth',
  'mb',
  'minHeight',
  'minWidth',
  'ml',
  'mr',
  'mt',
  'mx',
  'my',
  'opacity',
  'order',
  'overflow',
  'overflowX',
  'overflowY',
  'p',
  'padding',
  'paddingBottom',
  'paddingLeft',
  'paddingRight',
  'paddingTop',
  'paddingX',
  'paddingY',
  'pb',
  'pl',
  'position',
  'pointerEvents',
  'pr',
  'pt',
  'px',
  'py',
  'right',
  'size',
  'textAlign',
  'textDecoration',
  'textShadow',
  'textTransform',
  'transition',
  'top',
  'tooltipBorder',
  'userSelect',
  'verticalAlign',
  'whiteSpace',
  'wordBreak',
  'width',
  'zIndex',
  'overflowWrap',
  'transform',
  'transformOrigin',
  'outline',
  'manager',// removes the manager attribute passed to anything use the modal
  'clickEvent',
  'caretColor',
  'clipPath',
  'objectFit',
  'objectPosition'
];

export const baseExcludeProps = arrayToObject(baseExcludePropsArray);

// hoc that creates a function to pass to styled components for
// excluding properties to html
export function shouldForwardProp(propsToExcludeArray: string[] = []) {
  // convert the arrays to a map for performance reasons
  const propsToExclude = arrayToObject(
    propsToExcludeArray,
    Object.assign({}, baseExcludeProps)
  );

  return function(prop: string, defaultValidatorFn: (prop: string) => boolean): boolean {
    return !propsToExclude[prop];
  };
}

// simple helper for created a styled component + style system to avoid some of the
// ugly syntax of styled system
// in the propsToExclude you do not need to add ComponentProps, but if your component has
// extended another component, you need to provide those props plus additional ones you've added
export function createComponent<C extends keyof JSX.IntrinsicElements | React.ComponentType<any>>(component: C, propsToExclude?: string[]) {
  return styled(createComponentBase(component)).withConfig({
    shouldForwardProp: shouldForwardProp(propsToExclude) as any
  });
}

export type ReactSetState<T> = (value: React.SetStateAction<T>) => void
