import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
export type QuestionFieldsFragment = {
  id: string;
  ownerId?: string | null | undefined;
  ownerType?: Types.CfOwnerKind | null | undefined;
  kind?: Types.CfKind | null | undefined;
  title?: string | null | undefined;
  description?: string | null | undefined;
  choices?: Array<string> | null | undefined;
  stage?: Types.CfStage | null | undefined;
  required?: boolean | null | undefined;
  owner?: { name?: string | null | undefined } | { name: string } | { name: string } | null | undefined;
  files?: Array<{ id: string; name: string; fileType?: string | null | undefined } | null | undefined> | null | undefined;
};

export const QuestionFieldsFragmentDoc = gql`
  fragment questionFields on CfQuestion {
    id
    ownerId
    ownerType
    owner {
      name
    }
    kind
    title
    description
    choices
    stage
    required
    files {
      id
      name
      fileType
    }
  }
`;
