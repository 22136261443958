import * as React from 'react'
import { useParams, useHistory, Redirect } from 'react-router'

import { Beta } from 'app2/views/shared-public';

import { OrganizerPage } from '../shared'
import { SeasonRouteParams } from '../organizerRoutes';

import { SchoolInfo } from './SchoolInfo'
import { EmergencyContacts } from './EmergencyContacts'
import { Grades } from './Grades'
import { Teachers } from './Teachers'
import { Dismissal } from './Dismissal'
import { Pickup } from './Pickup'
import { Attendance } from './Attendance';
import { Compliance } from './Compliance'
import { TimeTracking } from './TimeTracking'
import { Branding } from './Branding'
import { Embed } from './Embed'

import { useCurrentSite } from '../shared';

export function GeneralInfo() {
  const { site: siteId } = useParams<SeasonRouteParams>();
  const { site } = useCurrentSite();
  const usingPickups = site?.usingPickups

  const h = useHistory();
  
  if (h.location.search.indexOf('code=') != -1) {
    return <Redirect to={{...h.location, pathname: `/organizer/${siteId ? siteId + '/' : ''}payment`}} />
  }

  return <OrganizerPage title='General info'>
    <SchoolInfo />
    <EmergencyContacts />
    <Teachers />
    <Grades />
    <Dismissal />
    {usingPickups ? <Pickup /> : <Beta position='top'><Pickup /></Beta>}
    <Attendance />
    <Compliance />
    <TimeTracking />
    <Branding />
    <Embed />
  </OrganizerPage>
}