import * as React from 'react';

import { HBox, Link, Text } from 'app2/components'
import { PrivatePage, PrivatePageProps } from 'app2/views/shared';
import { useCurrentUser } from 'app2/views/shared-public';

import { InactiveWarning } from './InactiveWarning';
import { ClaimSite } from './claim-site';
import { SiteDropdown, useSites, useCurrentSite } from '.';

interface Props extends PrivatePageProps {
  sites?:boolean
}

export function OrganizerPage(props:Props) {
  const { sites:showSites, ...remaining} = props;
  const { loading:loadingUser } = useCurrentUser();
  const { site, loading:loadingSite } = useCurrentSite();
  const { sites, loading:loadingSites } = useSites();
  const noSites = !loadingUser && !site && !loadingSite && !sites?.length && !loadingSites;

  function render() {
    if (noSites) {
      return <ClaimSite />;
    }

    return <PrivatePage actions={showSites && <SiteDropdown />} above={!site ? null : renderProviderSearchCTA()} {...(!site ? null : remaining)}>
      <InactiveWarning />
      {props.children}
    </PrivatePage>
  }

  function renderProviderSearchCTA() {
    return <HBox bg='successFill' border='solid 1px' borderColor='success' borderRadius='standard' p='$12' mb='$12' position='sticky' left={0} display={['none','flex']}>
      <Text text='body' flex={1}>Find more providers to teach at your school</Text>
      <Link to={`/directory/provider?site=${site?.slug}`} button='primary'>Provider directory</Link>
    </HBox>
  }

  return render();
}

OrganizerPage.defaultProps = {
  sites: true
}