import { parse, print, visit, DocumentNode, FieldNode, InlineFragmentNode } from 'graphql';

// written by chatgpt, so possibly its buggy
// dottedPaths can be ['field1', 'field2', 'path1.fieldX', 'path2.fieldY']
export function removeQueryFields(query: string | DocumentNode, dottedPaths: string[]) {
  const paths = dottedPaths.map(path => path.split('.'));
  const ast = typeof query == 'string' ? parse(query) : parse(print(query));

  const modifiedAst = visit(ast, {
    Field(node: FieldNode) {
      for (const fieldPath of paths) {
        const updatedNode = removeFieldByPath(node, fieldPath);
        if (updatedNode === null) {          
          return null;
        }

        node = updatedNode;
      }

      return node;
    }
  });

  // for unknown reasons even though we clone the ast above, it still seems
  // to not get recognized properly useQuery, so we print to force it
  return print(modifiedAst);
}

function removeFieldByPath(node:FieldNode, fieldPath: string[]): FieldNode {
  if (node.name.value !== fieldPath[0]) {
    return node;
  }

  return visitByPath(node, fieldPath) as FieldNode;
}

function visitByPath(node:FieldNode | InlineFragmentNode, fieldPath: string[]): FieldNode | InlineFragmentNode | null {
  // If it's the last field in the path, remove it
  if (fieldPath.length === 1) {
    return null;
  }

  return visitSelectionSet(node, fieldPath) as FieldNode;
}

function visitSelectionSet(node:FieldNode | InlineFragmentNode, fieldPath: string[]): FieldNode | InlineFragmentNode | null {
  // If there are more fields in the path, continue traversing the selection set
  if (node.selectionSet) {
    const newSelections = node.selectionSet.selections
      .map(selection => {
        if (selection.kind == 'InlineFragment') {
          return visitSelectionSet(selection as InlineFragmentNode, fieldPath);
        }
        else
        if (selection.kind === 'Field') {
          return removeFieldByPath(selection as FieldNode, fieldPath.slice(1));
        }

        return selection;
      })
      .filter(Boolean);

    return newSelections.length == 0
    ? null
    : {
      ...node,
      selectionSet: {
        ...node.selectionSet,
        selections: newSelections
      }
    };
  }

  return node;
}
