import * as React from 'react'
import { last } from 'lodash-es'

import { List, ListProps } from './list';
import { HRule } from './HRule';
import { InputProps } from './Input'
import { Option, OptionValue, compareOptionValues, getOptionLabel, getOptionLabelForCompare } from './Option'
import { OptionText } from './OptionText';
import { IconNames } from './icons';
import { dispatchChangeEvent } from './dom-utils';
import { DropdownBase, DropdownState, DropdownTriggerType } from './DropdownBase';
import { BreakpointInfo } from './theme';
import { MultiContext, TypePropsOrElement, createOrClone } from './utils';

export interface DropdownProps extends Omit<InputProps, 'onChange' | 'list' | 'tags' | 'clearable'> {
  options:ReadonlyArray<string | Option>;
  multiple?:boolean;
  // if the dropdown supports multiple and you
  // want to match when a separator is typed
  // specify the spearator and it will be used to parse
  // the input
  separator?:string;
  selectedStyle?: 'bar' | 'checkbox';
  // shows the list underneath the input and not as a dropdown
  inlinelist?:boolean;
  measuredRows?:boolean;
  maxLines?:number;
  list?:Partial<ListProps>;
  icon?: IconNames;
  tags?: boolean;
  modal?: boolean;//show the dropdown as a modal
  tooltips?: boolean;//shows tooltips for list items
  clearOnSelect?: boolean;
  // see description in list
  enterToggleSelects?:boolean;
  nowrap?:boolean;
  onChange?: React.ChangeEventHandler<Dropdown>;
  onNoChange?: ()=> void;
  onInputChange?: (value:string) => void;
  // if you provide a search handler, the default search will not be used
  onSearchChange?: (value:string) => void;
  
  // allows you to know when the dropdown open/close state changes
  // you can use this to delay loading options
  onDropdown?:(open?:boolean) => void;
  
  // allows the user to add to the dropdown
  // if a string is specified, then it will always show
  // that string as an option, to indicate to the user they can
  // add more options, regardless of whether the user has typed in anything
  additions?:boolean|string;
  // alows change the "add" term
  additionTerm?:string;

  // add XXX position
  addPosition?: 'top' | 'bottom';

  // when allowing additions, allows you to customize the option value
  // if additions=true and onAdd is not specified, the value will 
  // be the newly added text...you must also provide new options prop 
  // with the new option added
  onAdd?: (text:string, props:DropdownProps) => OptionValue;
  // dropdowns support search but on the phone that causes the
  // browser to shrink the screen and scroll.  set this to true
  // if you want to make the input readonly on the phone.
  disablePhoneInput?:boolean;

  // allows the user to clear the current choice or the current text
  clearable?:boolean | 'text';

  // allows overriding the input portion of the dropdown
  input?:TypePropsOrElement<React.ComponentType<{multiple?:boolean, options:Option[], input:InputProps}>>;

  // by default the dropdown width is the same as the input area
  dropdownWidth?:number;
  minDropdownWidth?:number;
}

interface State extends DropdownState {
  selected?:OptionValue[];
  focus?:Option;
  text?:string;
  tags?:Option[];
  selectedOption?:Option;
}

const ADD_VALUE = '___add';

export class Dropdown extends DropdownBase<DropdownProps, State> {
  static contextType = MultiContext;
  static defaultProps = {
    options: [] as Option[],
    multiple: false,
    tags: true,
    clearOnSelect: true,
    enterToggleSelects: true,
    input:OptionText
  }

  context:BreakpointInfo;
  state:State;
  outerInputRef = React.createRef<HTMLDivElement>();
  inputRef = React.createRef<HTMLInputElement>();
  listRef = React.createRef<List>();
  // not stored in state because of react not updating state immediately
  options?:Option[];
  // options before filtering.  we need to store and can't rely on props, because
  // the options might have been loaded dynamically via a callback
  unfilteredOptions?:Option[];
  sizeDropdownToTrigger = true;
  hasSearch = false;
  adding = false;

  constructor(props:DropdownProps) {
    super(props);
    this.state = {...this.updateOptions()};
    this.inline = this.props.inlinelist;
    this.modal = this.props.modal;
  }

  componentDidUpdate(prevProps:DropdownProps, prevState:State) {
    super.componentDidUpdate(prevProps, prevState);

    this.inline = this.props.inlinelist;
    this.modal = this.props.modal;

    const valueChange = !compareOptionValues(this.props.value, prevProps.value) || 
      // this checks the case when our prop value is updated before the options that
      // would point to the value are updated and then the options are updated..so
      // we are looking for a disagreement between our internal selected state and
      // what the props are saying (this really needs to be updated such that things
      // are derived properly from props)
      (this.props.value && !this.isSelected(this.props.value) && this.props.options?.find(o => compareOptionValues((o as Option)?.value, this.props.value)));

    const optionsChanged = this.props.options !== prevProps.options || (valueChange && this.valueIsOptions);

    if (optionsChanged) {
      const text = this.state.text;
      const hasSearch = this.hasSearch;
      const state = this.updateOptions();

      if (!valueChange && text) {
        state.text = text;
        this.hasSearch = hasSearch;
      }

      // this attempts to preserve the input text when the options change
      // because options changing can be caused by loading options on demand
      // but if the value also changed, then we do not want to preserve the text
      this.setState(state, () => {
        if (!valueChange && text && this.state.text != text) {
          this.setInputText(text);
        }
      });
    }
    else
    if (valueChange) {
      this.setState(this.updateSelectedFromProps());
    }
    else {
      const selectedLabelChanged = !this.props.onSearchChange && !this.props.multiple && !this.hasSearch && this.state.text != getOptionLabel(this.state.selectedOption);

      if (selectedLabelChanged) {
        this.setState(this.updateSelectedFromProps());
      }
    }
  }

  get controlled() {
    return 'value' in this.props;
  }

  get list() {
    return this.listRef.current;
  }

  get inputText() {
    return this.state.text;
  }

  renderTrigger() {
    const {options, multiple, separator, selectedStyle, inlinelist, measuredRows, maxLines, value, icon, tags, modal, tooltips, clearOnSelect, enterToggleSelects, list, onChange, onNoChange, onInputChange, onSearchChange, onDropdown, additions, additionTerm, addPosition, onAdd, disablePhoneInput, clearable, input, onBlur, width, height, dropdownWidth, minDropdownWidth, ...remaining} = this.props;

    // hack that if using styled options we will take some of the padding props to try and prevent things from moving
    const styledOption = Boolean(!this.props.multiple && typeof this.state.selectedOption?.label == 'object' && !React.isValidElement(this.state.selectedOption?.label)) ? {pl: '$12', py:'4.5px'} : undefined;

    return createOrClone(OptionText, {multiple: this.props.multiple, options: this.unfilteredOptions, input: {outerRef:this.outerInputRef, ref:this.inputRef, icon:icon || this.icon, onChange:this.onInputChange, onBlur:this.onInputBlur,
      onTagCloseClick:this.onTagCloseClick, onIconMouseDown:this.toggleDropdown, ...remaining, width:'100%', inputProps:styledOption,
      tags:this.state.tags, value: this.state.text, readOnly:this.props.disablePhoneInput && this.context.breakpoint == 0, clearable: Boolean((this.props.clearable == true && this.state.selected?.length) || (this.props.clearable === 'text' && this.state.text)), onClear: this.onClear}},
      input)
  }

  renderDropdown() {
    return <List ref={this.listRef} width={this.listWidth} onChange={this.onSelectionChange} onFocusChange={this.onFocusChange} onNoChange={this.onSelectionNoChange} value={this.state.selected} enterToggleSelects={this.props.enterToggleSelects}
      options={this.options} multiple={this.props.multiple} focusable={false} selectedStyle={this.props.selectedStyle} measuredRows={this.props.measuredRows} maxLines={this.props.maxLines} height={this.props.height} tooltips={this.props.tooltips} {...this.props.list} />
  }

  get listWidth() {
    if (this.props.dropdownWidth) {
      return this.props.dropdownWidth;
    }

    if (this.state.dropDownWidth !== undefined) {
      return this.state.dropDownWidth.endsWith('%')
        ? this.state.dropDownWidth
        : Math.max(parseFloat(this.state.dropDownWidth), this.props.minDropdownWidth || 0);
    }

    const w = this.triggerWidth;
    const result = typeof w == 'number'
      ? Math.max(w, this.props.minDropdownWidth || 0)
      : typeof w == 'string'
        ? w.endsWith('%')
          ? w
          : Math.max(parseFloat(w), this.props.minDropdownWidth || 0)
        : w;
    
    return result;
  }

  get icon() {
    if (this.props.inlinelist) {
      return 'Search';
    }

    return this.open ? 'DropdownClose' : 'DropdownOpen';
  }

  get value() {
    return this.props.multiple ? this.state.selected.slice() : this.state.selected[0];
  }

  get supportsAdditions() {
    return this.props.additions || this.props.onAdd || this.props.additionTerm;
  }

  onOpen() {
    // opening the dropdown on iOS immediately shows the text menu
    // (copy, cut, select all, etc) which makes it harder to select
    // and item in the dropdown.  clearing the selection seems to
    // to prevent this behavior.
    
    if (this.context.breakpoint == 0) {
      this.inputRef.current.setSelectionRange(0, 0);
    }

    this.props.onDropdown?.(true);
  }

  onClose() {
    this.setState({focus: undefined});
    this.props.onDropdown?.(false);
  }

  onInputChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    this.setInputText(value);
  }

  onInputBlur = (event:React.FocusEvent<HTMLInputElement>) => {
    this.possiblySelectOrAddFromInput([this.state.text || ''], false, false);

    this.clearSearch();
    
    this.props.onBlur?.(event);
  }

  onClear = () => {
    if (this.props.clearable === 'text') {
      this.setInputText('');
    }
    else {
      this.updateSelectedFromInteraction([], true);
    }
  }

  onTriggerKeyDown(event:React.KeyboardEvent<DropdownTriggerType>) {
    if (event.key == 'Tab') {
      this.possiblySelectOrAddFromInput([this.state.text || ''], false, true);
    }

    if (event.key == 'Escape' && !this.open) {
      this.clearSearch();
    }

    super.onTriggerKeyDown(event);
  }

  onTagCloseClick = (tag:Option, index:number) => {
    let selected:OptionValue[] = this.state.selected.filter(value => !compareOptionValues(tag.value, value));

    this.updateSelectedFromInteraction(selected, true);
  }

  onSelectionChange = (event:{currentTarget:{value:OptionValue[]}}) => {
    const selected = Array.isArray(event.currentTarget.value) ? event.currentTarget.value : [event.currentTarget.value];

    if (selected.length && selected[selected.length - 1] == ADD_VALUE) {
      this.updateSelectedAddNewFromInteraction(selected, true);
    }
    else {
      this.updateSelectedFromInteraction(selected, true);
    }
  }

  onFocusChange = (focus:Option) => {
    this.setState({focus});
  }

  async updateSelectedAddNewFromInteraction(selected:any[], retainFocus:boolean) {
    if (this.adding) {
      return;
    }

    try {
      this.adding = true;

      let value;

      if (this.props.onAdd) {
        const addOption = this.options.find(option => option.value == ADD_VALUE);

        if (!addOption) {
          return;
        }

        const addPrompt = addOption.text;
        value = this.props.onAdd(addPrompt != this.props.additions ? addPrompt : '', this.props);
      }
      else {
        value = this.state.text;
        this.unfilteredOptions.push({label: this.state.text, value});
        this.options = this.unfilteredOptions;
        this.hasSearch = false;
        this.addAddPrompt();
      }

      if (value?.then && value?.finally) {
        value = await value;

        // since this is async, its possible that we've already been
        // re-rendered with the new item selected, so check that
        if (this.isSelected(value)) {
          return;
        }
      }

      // if a valid value isn't returned, undo the addition
      if (value === undefined || value === null || !selected?.length) {
        this.setState(this.updateSelectedFromProps());
      }
      else {
        selected[selected.length - 1] = value;
        this.updateSelectedFromInteraction(selected, retainFocus);
      }
    }
    finally {
      this.adding = false;
    }
  }

  onSelectionNoChange = () => {
    this.closeDropdown(true);
    this.props.onNoChange?.();
  }

  clearOnSelect() {
    // clear the filter on selecting a new item (this mimics what semantic ui dropdown does)
    // the default is to clear on select, but it can be turned off, such as for an
    // inline checkbox list that is showing search results (such as the column menu in the table)
    if (this.props.clearOnSelect) {
      this.options = this.unfilteredOptions;
      this.hasSearch = false;
      this.addAddPrompt();
    }
  }

  // if allowing multiple values and the input text 
  // contains a separator try to match and end those values
  // and commit them as if the user selected them
  // else just set the text as a search value
  
  setInputText(value:string) {
    if (this.props.separator && value.indexOf(this.props.separator) != -1) {
      const parts = value.split(this.props.separator).filter(v => v.length != 0);
      value = this.possiblySelectOrAddFromInput(parts, true, false);
    }

    this.setInputTextSingleValue(value);
  }

  possiblySelectOrAddFromInput(separatedInputs:string[], retainFocus:boolean, allowPartialMatch:boolean) {
    let value:string = '';

    separatedInputs = separatedInputs.filter(part => part.length != 0);

    if (!separatedInputs.length && this.props.clearable) {
      this.updateSelectedAddNewFromInteraction([], retainFocus);
    }

    while (separatedInputs.length) {
      const part = separatedInputs.shift().trim();
      const lastPart = !separatedInputs.length;
      const partUpper = part.toUpperCase();
      // matching on selected takes priority in case there are options 
      // with the same text values, so we don't select something else
      let match = getOptionLabelForCompare(this.state.selectedOption) == partUpper 
        ? this.state.selectedOption
        : this.unfilteredOptions.find(option => getOptionLabelForCompare(option) == partUpper);

      this.setInputTextSingleValue(part);

      // if we didn't find an exact match, if allowing partial matches, and if
      // add is not the only option, then select the option with focus
      if (!match && allowPartialMatch && lastPart && this.options.length && this.options[0]?.value !== ADD_VALUE) {
        match = this.state.focus !== undefined ? this.state.focus : this.unfilteredOptions[0];
      }

      // similate the user hitting enter "add XXX" if additions are supported
      if (!match && this.supportsAdditions && this.options.length && last(this.options).value == ADD_VALUE) {
        const selected = this.props.multiple ? [...this.state.selected, ADD_VALUE] : [this.state.text];
        this.updateSelectedAddNewFromInteraction(selected, retainFocus);
      }
      else
      // or the user hitting enter on the matching selection
      if (match && !this.isSelected(match.value)) {
        const selected = this.props.multiple ? [...this.state.selected, match.value] : [match.value];

        this.updateSelectedFromInteraction(selected, retainFocus);
      }
      else {
        value = part;
      }
    }

    return value;
  }

  setInputTextSingleValue(value:string) {
    this.hasSearch = true;

    if (this.props.onSearchChange) {
      this.props.onSearchChange(value);
    }
    else {
      const valueUpper = value.toUpperCase().trim();
      const options = this.unfilteredOptions.filter(option => getOptionLabelForCompare(option).indexOf(valueUpper) != -1);
      this.options = options;

      const hasMatch = options.find(option => getOptionLabelForCompare(option) == valueUpper) != null;

      if (!hasMatch && this.supportsAdditions) {
        if (value) {
          this.addAddOption(options, value);
        }
        else {
          this.addAddPrompt();
        }
      }
    }

    this.props.onInputChange?.(value);
    this.setState({text: value});
  }

  clearSearch() {
    if (this.options != this.unfilteredOptions) {
      this.options = this.unfilteredOptions;
      this.hasSearch = false;
      this.addAddPrompt();
      this.setState(this.updateTextAndTags(this.state.selected));
    }
  }

  get valueIsOptions() {
    return !this.props.options?.length && this.props.tags && this.props.multiple && Array.isArray(this.props.value)
  }

  updateOptions():State {
    // try to automatically create the options when we have none but have a value
    const options = this.valueIsOptions
      ? this.props.value.slice()
      : this.props.options

    this.options = this.unfilteredOptions = this.mapOptions(options || []);
    this.addAddPrompt();

    // hack so that we don't clear the input value when using a search callback
    // because we interpret any change to options as not a reset where we need
    // to change out the selected value and text value, but an incremental search
    if (this.props.onSearchChange && compareOptionValues(this.state.selected?.[0], this.props.value)) {
      return {};
    }

    this.hasSearch = false;

    return this.updateSelectedFromProps();
  }

  mapOptions(options:ReadonlyArray<string | Option>) {
    if (!options) {
      return [];
    }

    return options.map((option:Option | string) => typeof option === 'string' ? {label: option, value: option} : option);
  }

  // for a selection that is controlled
  updateSelectedFromProps() {
    let selected:OptionValue[] = [];
    
    if (this.controlled) {
      if (Array.isArray(this.props.value)) {
        selected = this.props.value;
      }
      else
      if (this.props.value !== undefined && this.unfilteredOptions.find(o => compareOptionValues(o.value, this.props.value))) {
        selected = [this.props.value];
      }
      else {
        selected = []
      }
    }
    else 
    if (this.state?.selected !== undefined) {
      selected = this.state.selected.slice();
    }

    selected = this.removeDuplicateSelected(selected);
    return {selected, ...this.updateTextAndTags(selected)};
  }

  // for a selection that is uncontrolled or if controlled we dispatch an event
  updateSelectedFromInteraction(selected:OptionValue[], retainFocus:boolean) {
    this.clearOnSelect();

    selected = this.removeDuplicateSelected(selected);

    if (!this.controlled) {
      this.setState({selected, ...this.updateTextAndTags(selected)});
    }
    
    this.dispatchChangeEvent(selected);

    if (!this.props.multiple) {
      this.closeDropdown(retainFocus);
    }
  }

  updateTextAndTags(selected:OptionValue[]) {
    // use unfiltered options to generate tag list as we don't filter tags
    const tags:Option[] = !this.props.tags ? [] : selected.map(value => this.unfilteredOptions.find(option => compareOptionValues(option.value, value))).filter(option => option !== undefined && option.label != null);
    const multiple = this.props.multiple;
    const filtered = this.options?.length != this.unfilteredOptions?.length;
    // if we are a multiple selection list and there's a filter, keep showing the filter
    // single select removes the filter
    const text = multiple && filtered 
      ? this.state.text 
      : !multiple 
        ? tags.map(tag => getOptionLabel(tag)).join('; ') 
        : '';

    return {tags: multiple ? tags : undefined, text, selectedOption: tags[0]};
  }

  removeDuplicateSelected(values:OptionValue[]) {
    const set = new Set();

    return values.filter(sel => {
      if (set.has(sel)) {
        return false;
      }

      set.add(sel);
      return true;
    });
  }

  addAddPrompt() {
    if (typeof this.props.additions != 'string') {
      return
    }

    this.addAddOption(this.options, this.props.additions, this.props.addPosition == 'top');
  }

  addAddOption(options:Option[], prompt:string, prepend?:boolean) {
    this.removeAddOption(options);

    const option = {label: <><b>{this.props.additionTerm || 'Add'}</b> {prompt}</>, value: ADD_VALUE, text: prompt};

    if (prepend) {
      options.unshift(option);
    }
    else {
      options.push(option);
    }
  }

  removeAddOption(options:Option[]) {
    const pos = options.findIndex(o => o.value == ADD_VALUE);
    
    if (pos == -1) {
      return;
    }

    options.splice(pos, 1);
  }

  isSelected(value:OptionValue) {
    return (this.state.selected || [])?.findIndex(selected => compareOptionValues(selected, value)) != -1;
  }

  dispatchChangeEvent(selected:OptionValue[]) {
    if (!this.props.onChange) {
      return;
    }

    const value = this.props.multiple ? selected : selected[0];
    dispatchChangeEvent(this, value, this.props.onChange);
  }
}

export const DROPDOWN_SEPARATOR = {disabled: true, label: <HRule pointerEvents='none' disabled={true} />, value: 'separator'};
