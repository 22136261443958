import * as React from 'react';
import { useParams } from 'react-router';

import { CourseKindGroup } from 'app2/api';
import { Button, HBox, VBox, Subtitle2 } from 'app2/components';
import { CourseKindGroupPicker } from 'app2/views/shared-public';
import { CourseTablesForGroups, Module, useCreateActivitiesButton } from 'app2/views/shared';

import { SeasonRouteParams } from '../organizerRoutes';
import { useOrganizerSeasonQuery, useSeasons } from '../shared';

import { SeasonCoursesTable } from './SeasonCoursesTable';
import { ImportActivitiesModal } from './ImportActivitiesModal';
import { useNavigateToTableAndEdit } from './useNavigateToTableAndEdit';
import { organizerUpsertSeason } from './gql';

export function SeasonCourses() {
  const { site, season: seasonId } = useParams<SeasonRouteParams>();
  const navigate = useNavigateToTableAndEdit();

  const {seasons} = useSeasons();

  const [seasonResult] = useOrganizerSeasonQuery({ variables: { seasonId } });
  const season = seasonResult?.data?.season;
  const activeSeason = season && !season.completed;

  const { editing, createActivitiesButton } = useCreateActivitiesButton(season?.courseKindGroups);
  const empty = season && !season.hasCourses && !editing;
  
  if (!seasonId) {
    return <span />;
  }

  function render() {
    return <Module title='Activities' actions={renderPrimaryActions()}>
      {empty ? renderNoActivities() : renderCourseGroups()}
    </Module>
  }

  function renderCourseGroups() {
    return <CourseTablesForGroups groups={season?.courseKindGroups} component={SeasonCoursesTable} />
  }

  function renderPrimaryActions() {
    return !activeSeason || empty ? [] : [renderImport()];
  }

  function renderNoActivities() {
    if (!activeSeason) {
      return renderNoActivitiesCompletedSeason();
    }

    return <VBox hAlign='center' background='white'>
      <Subtitle2 mt='170px' mb='$30' maxWidth='600px' textAlign='center'>No activities yet!</Subtitle2>
      <HBox layout={['vbox', 'hbox', 'hbox']} gap='$8'>
        {renderImport()}
        {createActivitiesButton}
      </HBox>
    </VBox>
  }

  function renderNoActivitiesCompletedSeason() {
    return <Subtitle2>There are no activities in this season!</Subtitle2>
  }

  function renderImport() {
    return seasons?.length > 1 && <Button key='import' kind='secondary' onClick={<ImportActivitiesModal key='import' season={seasonId} site={site} />}>Copy a past season</Button>
  }

  function renderCourseKindPicker() {
    return <CourseKindGroupPicker key='picker' selected={season?.courseKindGroups} onChange={onChangeKindGroups} type='button-list' kind='secondary' selectedStyle='checkbox' multiple>Add activity type</CourseKindGroupPicker>;
  }

  async function onChangeKindGroups(event:React.ChangeEvent<{selected:CourseKindGroup[]}>) {
    const courseKindGroups = event.target.selected;
    const [success] = await organizerUpsertSeason({ variables: {attributes: { id: seasonId, courseKindGroups }} });
    const adding = courseKindGroups.length > season.courseKindGroups.length;

    if (success && adding) {
      //the last one in the list will be the one that was just added
      const added = courseKindGroups[courseKindGroups.length - 1];
      navigate(added);
    }
    
    return success;
  }

  return render();
}
