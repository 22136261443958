import * as React from 'react'
import { useParams } from 'react-router-dom';

import { axios } from 'app2/api';
import { useLifecycle, VBox } from 'app2/components';
import { Loader } from 'app2/components';

import { NotFound } from './NotFound';

export function FileViewer() {
  const { id } = useParams<{id: string}>();

  const [notFound, setNotFound] = React.useState(false);
  useLifecycle({onMount});

  function render() {
    return notFound ? <NotFound /> : <VBox hAlign='center' vAlign='center' height='100%'><Loader /></VBox>
  }

  async function onMount() {
    try {
      const response = await axios.get('/file_uploads/' + id);
      window.location.replace(response.data);
    }
    catch(e) {
      setNotFound(true);
    }
  }

  return render();
}
