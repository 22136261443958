import * as React from 'react'

import { NavigationLink as NavigationLinkBase, NavigationLinkProps } from 'app2/components';

import { CurrentUserInfo, useCurrentUser } from '../user';

import { betaImageCssLeft, useShowBeta } from './Beta';
import { useRouteInfo } from './useRouteInfo';

interface Props extends NavigationLinkProps {
  hide?:(user:CurrentUserInfo['user']) => boolean;
}

export function NavigationLink(props:Props) {
  let {label, hide, ...remaining} = props;

  const {route, allowed} = useRouteInfo(props.to);
  const beta = route?.props?.beta;
  const { user } = useCurrentUser();

  if (beta && !useShowBeta()) {
    return <></>;
  }

  if (!allowed) {
    return <></>;
  }

  if (hide && hide(user)) {
    return <></>;
  }

  const css = beta ? betaImageCssLeft : undefined;

  return <NavigationLinkBase css={css} label={label} {...remaining}/>
}
