import * as React from 'react'

import { FieldProps } from './Field';
import { FieldInfo } from './FormModel';

export type FCT = keyof JSX.IntrinsicElements | React.JSXElementConstructor<any>;//FCT = FieldComponentType

export type ParseHandler = (value: any, field:FieldProps<any, any, any>, info:FieldInfo<any>) => any;
export type FormatHandler = (value: any, field?:FieldProps<any, any, any>, info?:FieldInfo<any>) => any;
export enum FieldPlaceholder {
  enter = 1,
  select
}
export type FieldPlaceholderType = boolean | FieldPlaceholder | string | FormatHandler;

export type FieldComponentProps<T extends FCT> = {
  placeholder?: FieldPlaceholderType;
  // show a 'None' label when there's no entries
  // either pass true to show "None" or a custom none message 
  // none will show, instead of the component, when the form
  // value is undefined, null, "" or []
  // none is only applied during display mode
  none?:boolean | React.ReactNode;

  format?: FormatHandler;
  parse?: ParseHandler;
  // copy & paste
  // by default copy & paste will use
  // format (for copy) and parse (for paste)
  // if specified.  however if you need to customize
  // for copy & paste, you can specify these attributes.
  // copy & paste will look do the following use the following (in order)
  // - copy - copy method, format method, get raw value
  // - paste - paste method, parse method, set raw value
  copy?: FormatHandler;
  paste?: ParseHandler;

  // for displaying as required w/o actually being required
  displayRequired?:boolean;

  // only applies to repeating section and indicates if the component should fill the column
  stretch?:boolean;

  valueProperty?: string;
  getValueProperty?: string;
  onChangeProperty?: string;
  onBlurProperty?: string;
  errorProperty?: string;
  fieldProperty?: string;
  disabledProperty?: string;
  readOnlyProperty?: string;
  disallowNone?: boolean;
  labelProperty?: string;
  formModeProperty?: 'display' | 'edit';

  changeHandler?: (...args: any[]) => any;
  blurHandler?: () => void;
  component?:T | FieldComponentProps<T>;

  onClick?: (event:React.MouseEvent, info:FieldInfo<T>) => void;
} & Omit<React.ComponentProps<T>, 'display'>

export function chain(handlers:(FormatHandler | ParseHandler)[]) {
  return (value: any, field:FieldProps<any, any, any>, info:FieldInfo<any>) => {
    return handlers.reduce((val, parse) => parse(val, field, info), value);
  }
}