import { colId, DataTableColumn } from '../column';

import { ExpressionNode } from './ExpressionNode';
import { filterToString } from './filterToString';
import { parse } from './filter';

export class FilterParser {
  cols:DataTableColumn[];

  constructor(cols:DataTableColumn[]) {
    this.cols = cols
  }

  parse(input:string) {
    if (!input) {
      return null;
    }

    return parse(input, {findColId: this.findColId})
  }
  
  validate(input:string) {
    if (!input) {
      return true;
    }

    try {
      parse(input);
      return true;
    }
    catch(e) {
      if (e.message.startsWith('Expected')) {
        return 'Invalid filter';
      }
  
      return e.message;
    }
  }

  toString(parsed:ExpressionNode) {
    return filterToString(parsed, this.cols);
  }
  
  findColId = (colName:string) => {
    const found = this.cols.find(c => c.label.toString().localeCompare(colName, undefined, { sensitivity: 'accent' }) == 0);
  
    if (!found) {
      throw new Error("Invalid column name: " + colName);
    }
  
    return colId(found);
  }  
}
