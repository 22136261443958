import * as React from 'react'
import { useHistory } from 'react-router';
import queryString from 'query-string'

import { stripeConnectAPI } from 'app2/api';
import { standardizeAxiosApiError } from 'app2/api';
import { Img, Panel, Link, Text, Modal, Info, PopupManager, Button, VBox, useLoader } from 'app2/components'
import { handleErrors } from 'app2/views/shared';

import debitCard from './debit-card.png'

interface StripeData {
  stripeDashboardUrl?:string;
  stripeConnectUrl?:string;
  stripeAccountVerified?:boolean;
  stripeAccountType?:string;
}

interface Props {
  id:string;
  isSite?:boolean;
  query:() => StripeData;
}

export function StripeConnection(props:Props) {
  const history = useHistory();
  const stripeCode = React.useRef();
  const loader = useLoader();

  const stripeData = props.query();

  const label = Boolean(stripeData?.stripeDashboardUrl) ? 
                Boolean(stripeData?.stripeAccountVerified) ? 'Stripe Dashboard' : 'Verify business details'
                : 'Connect to Stripe'
  const url = stripeData?.stripeDashboardUrl || stripeData?.stripeConnectUrl

  function render() {
    return <Panel icon='DollarSign' title='Stripe information' subtitle='Homeroom uses Stripe to provide quick &amp; secure payments'>
      {renderAccountVerifyWarning()}
      {Boolean(stripeData?.stripeDashboardUrl) ? 
        <div><Link to={url} button='primary'>{label}</Link></div>
      : <div><Button onClick={showModal}>{label}</Button></div>}
      {renderDebitCardWarning()}
    </Panel>
  }

  function renderAccountVerifyWarning() {
    return stripeData?.stripeAccountType && !stripeData?.stripeAccountVerified && <Info type='warning'>{[
      'Click the button below to update your stripe business account details.',
      'For individuals, enter your personal address and last 4 SSN digits to become verified.',
      'For business entities, enter your last 4 SSN digits, business address and EIN to become verified.']}
   </Info>
 }

  function renderDebitCardWarning() {
    return (stripeData?.stripeAccountType == 'debit') && <Info type='warning'>Whoops! It looks like you've selected your debit card as your method
     to receive payouts. Please select the bank account option as your
     method to receive payouts. This allows us to process refunds for
     parents. If you believe that this is an error, please contact us at
     hello@homeroom.com.
   </Info>
 }

 function showModal() {
   PopupManager.addModal(<Modal title='Creating your Stripe Connect account' ok={null} cancel={null}>
     <Text text="body">
      When setting up your Stripe account, you will be given the option to 
      connect either to your bank account or debit card. We ask that you please 
      only connect your <b>bank account</b> as we cannot process refunds for debit cards. 
       <br />
       <br />
       Have questions? We’re here to help! Contact our{' '}
       <Link to="mailto:hello@homeroom.com" target="_blank">support team</Link>
       .
     </Text>
     <VBox hAlign="center" mt="$40">
       <Img src={debitCard} maxWidth='400px' mb="$40" />
       <Link to={url} button='primary'>{label}</Link>
     </VBox>
   </Modal>);
  }

  async function checkForStripeCode() {
    const params = queryString.parse(history.location.search)
    
    if (!params.code || stripeCode.current == params.code || !props.id) {
      return;
    }

    const dash = params.state.indexOf('-');
    const type = params.state.substring(0, dash);
    const slug = params.state.substring(dash + 1);

    if (type == 'company' && props.isSite) {
      history.replace(`/provider/${slug}/payment` + history.location.search);
      return;
    }

    if (type == 'site' && !props.isSite) {
      history.replace(`/organizer/${slug}/payment` + history.location.search);
      return;
    }

    stripeCode.current = params.code;

    try {
      loader.loader(true);
      
      await stripeConnectAPI(props.isSite, props.id, {code: params.code});
      history.replace(history.location.pathname);
      history.go(0);
    }
    catch(e) {
      handleErrors(null, standardizeAxiosApiError(e).errors);
    }
    finally {
      loader.loader(false);
    }

    return false;
  }

  checkForStripeCode();

  return render();
}
