import * as Types from '../../../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ChangeRecurringsMutationVariables = Types.Exact<{
  courseId: Types.Scalars['ID'];
  ids: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
  kind: Types.PriceConfigKind;
  recurring?: Types.InputMaybe<Types.RecurringPriceConfigInput>;
  dropIn?: Types.InputMaybe<Types.DropInPriceConfigInput>;
  discount?: Types.InputMaybe<Types.DiscountInput>;
  effective: Types.ChangePricingPeriod;
  description?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type ChangeRecurringsMutation = { enrollmentsChangeSubscription?: { enrollments?: Array<{ id: string } | null | undefined> | null | undefined } | null | undefined };

export const ChangeRecurringsDocument = gql`
  mutation ChangeRecurrings(
    $courseId: ID!
    $ids: [ID!]!
    $kind: PriceConfigKind!
    $recurring: RecurringPriceConfigInput
    $dropIn: DropInPriceConfigInput
    $discount: DiscountInput
    $effective: ChangePricingPeriod!
    $description: String
  ) {
    enrollmentsChangeSubscription(courseId: $courseId, ids: $ids, kind: $kind, recurring: $recurring, dropIn: $dropIn, discount: $discount, effective: $effective, description: $description) {
      enrollments {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type ChangeRecurringsOptions = ExecMutationOptions<ChangeRecurringsMutationVariables>;

export function changeRecurrings(options: ChangeRecurringsOptions) {
  return executeMutation<ChangeRecurringsMutation, ChangeRecurringsMutationVariables>(ChangeRecurringsDocument, options);
}
