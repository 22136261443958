import * as React from 'react'

import { Dropdown, Field, FieldInfo, Part, useFormInfo } from 'app2/components';

import { ClassOptionProps, EnrollmentWithClassOption } from './ClassOptionProps';

export function AfterClass(props:ClassOptionProps) {
  const info = useFormInfo();
  const enrollment = props.enrollment || info.form.getValue(info.parents, '') as EnrollmentWithClassOption;

  if (!enrollment.pickupDropoffRequired) {
    return <></>;
  }

  const options = props.siteOptions[enrollment.course.site.id]?.afterClass;
  const editable = Boolean(options?.length && info.editing && info.editing && (props.editable ?? !enrollment.isOption));
  const Tag = props.kind == 'field' ? Field : Part;

  return <Tag name='dismissal' label='Post-activity' required={pickupRequired} autoLoader onChange={props.onChange} editing={editable} edit={<Dropdown options={options} placeholder='Post-activity dismissal *' disablePhoneInput />} />
}

function pickupRequired(info:FieldInfo<EnrollmentWithClassOption>) {
  return info.record.pickupDropoffRequired;
}
